import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import api from '../../services/api'
import { Button, Form, Row, Col, Table, Spinner } from 'react-bootstrap'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'
import NavBar from '../../components/Navbar/navbar'
import { ToastContainer, toast } from 'react-toastify'
import { FaFileUpload } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";

function UploadAndMapColumns() {
  const [file, setFile] = useState(null)
  const [tablesInfo, setTablesInfo] = useState({})
  const [selectedTable, setSelectedTable] = useState('')
  const [columns, setColumns] = useState([])
  const [mapping, setMapping] = useState({})
  const [encryptPassword, setEncryptPassword] = useState('yes')
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get('/api/getTablesInfo')
      .then(response => {
        setTablesInfo(response.data)
      })
      .catch(error => {
        console.error("Erro ao buscar informações das tabelas:", error)
      })
  }, [])

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      setColumns(data[0])
      setFile(file)
    }
    reader.readAsBinaryString(file)
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (!file || !selectedTable) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('mapping', JSON.stringify(mapping))
    formData.append('table', selectedTable)
    formData.append('encryptPassword', encryptPassword)

    try {
      await api.post('/api/upload', formData);
      toast.success('Dados cadastrados com sucesso!');
    } catch (error) {
      console.error("Erro ao enviar o arquivo:", error);
      toast.error('Erro ao enviar dados.');
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <NavBar />

      <div className='container'>
        <div className='headerContainer'>
          <div className='divheaderTittleMobile'>
            <div id="divHeaderTittle">
              <FaFileUpload className='fs-3' /><h3 id="tittleH2">Upload de Dados</h3>
            </div>
          </div>
          {/* <div style={{ display: 'flex' }}>
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              <Button variant="secondary"><BsArrowReturnLeft /> Voltar</Button>
            </NavLink>
          </div> */}
        </div>

        <ToastContainer />

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className='fw-semibold'>Selecione um arquivo</Form.Label>
                <Form.Control className='input-group-edit' type="file" onChange={handleFileChange} accept=".xlsx, .xls, .csv" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className='fw-semibold'>Selecione uma tabela</Form.Label>
                <Select
                  className='input-group-edit'
                  value={selectedTable ? { value: selectedTable, label: selectedTable } : null}
                  onChange={(e) => setSelectedTable(e.value)}
                  options={Object.keys(tablesInfo).map((table) => ({ value: table, label: table }))}
                  classNamePrefix="select"
                />
              </Form.Group>
              {selectedTable && tablesInfo[selectedTable].includes('password') && (
                <Form.Group className="mb-3">
                  <Form.Label className='fw-semibold'>Criptografar senha ?</Form.Label>
                  <Select
                    className='input-group-edit'
                    value={{ value: encryptPassword, label: encryptPassword === 'yes' ? 'Sim' : 'Não' }}
                    onChange={(e) => setEncryptPassword(e.value)}
                    options={[{ value: 'yes', label: 'Sim' }, { value: 'no', label: 'Não' }]}
                    classNamePrefix="select"
                  />
                </Form.Group>
              )}
            </Col>
            <Col md={6}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Coluna do Arquivo</th>
                    <th>Coluna da Tabela</th>
                  </tr>
                </thead>
                <tbody>
                  {columns.length > 0 && selectedTable && tablesInfo[selectedTable].map((column, index) => (
                    <tr key={index}>
                      <td>{column}</td>
                      <td>
                        <Select
                          value={{ value: mapping[column], label: columns[mapping[column]] }}
                          onChange={(e) => setMapping({ ...mapping, [column]: e.value })}
                          options={[
                            { value: null, label: 'Nulo' },
                            ...columns.map((col, idx) => ({ value: idx, label: col }))
                          ]}
                          classNamePrefix="select"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className='formGenMobile mb-4'>
            <Col xs lg="2">
              <Button className='text-nowrap' id="buttonNew" type="submit" disabled={loading}>
                <MdOutlineFileUpload className='fs-4' />
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    Salvando...
                  </>
                ) : 'Upload e Processar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default UploadAndMapColumns
