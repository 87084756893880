import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import { FiLayers } from "react-icons/fi";
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationBibliotecasLottie from './AnimationBibliotecasLottie/AnimationBibliotecasLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [biblioteca, setBiblioteca] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [bibliotecaIdToDelete, setBibliotecaIdToDelete] = useState('')
    const [bibliotecaNameToDelete, setBibliotecaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [bibliotecaPerPage] = useState(10)
    const indexOfLastBiblioteca = currentPage * bibliotecaPerPage
    const indexOfFirstBiblioteca = indexOfLastBiblioteca - bibliotecaPerPage
    const currentBiblioteca = biblioteca.slice(indexOfFirstBiblioteca, indexOfLastBiblioteca)
    const nPages = Math.ceil(biblioteca.length / bibliotecaPerPage)
    const [loading, setLoading] = useState(true);

    const getBiblioteca = async () => {

        setLoading(true);

        try {
            const [userPermissionsResponse, bibliotecaResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/biblioteca/index')
            ]);

            setUserPermissions(userPermissionsResponse.data.permissions);
            setBiblioteca(bibliotecaResponse.data.biblioteca);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/biblioteca/index')
        setBiblioteca(response.data.biblioteca)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (bibliotecaId, bibliotecaName) => {
        setModalShow(true)
        setBibliotecaIdToDelete(bibliotecaId)
        setBibliotecaNameToDelete(bibliotecaName)
    }

    const deleteBiblioteca = async (bibliotecaId) => {
        await api.delete(`/api/biblioteca/${bibliotecaId}`).then(() => {
            getBiblioteca()
            setModalShow(false)
            toast.success('Biblioteca deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteBibliotecaConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Biblioteca</h4>
                    <p>
                        Você tem certeza de que deseja remover esta biblioteca <span className='d-flex justify-content-center mb-0'>{props.bibliotecaname} ?</span>
                    </p>
                </Modal.Body>

                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteBiblioteca(props.bibliotecaid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditBibliotecaPage = (bibliotecaId) => {
        history(`/bibliotecas/edit/${bibliotecaId}`)
    }

    const showbliotecaPage = (bibliotecaId) => {
        history(`/bibliotecas/show/${bibliotecaId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getBiblioteca()
    }, [])

    const filteredBiblioteca = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentBiblioteca
        } else {
            return biblioteca.filter((biblioteca) => biblioteca.name.toLowerCase().includes(lowerSearch) || biblioteca.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, biblioteca, currentBiblioteca])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className='container'>
                    <DeleteBibliotecaConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        bibliotecaid={bibliotecaIdToDelete}
                        bibliotecaname={bibliotecaNameToDelete}
                    />

                    <div id='divContainer' className='container'>
                        <DeleteBibliotecaConfirmationModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            bibliotecaid={bibliotecaIdToDelete}
                            bibliotecaname={bibliotecaNameToDelete}
                        />

                        <div className='headerContainer'>
                            <div className='divheaderTittleMobile'>
                                <div id='divHeaderTittle'><AnimationBibliotecasLottie /><h3 id='tittleH2'>Bibliotecas</h3></div>
                            </div>

                            <div id='div-input-group' className='div-input-group'>
                                <InputGroup id='input-group' className='input-group'>
                                    <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="pesquisar"
                                        aria-label="pesquisar"
                                        aria-describedby="search"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>

                                {
                                    userPermissions.some((permission) => permission.name === 'store_biblioteca') ? (
                                        <div className='btnVoltarMobile'>
                                            <Button className='borderbtnCreateEdit' onClick={() => history('/bibliotecas/new')} id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Nova Biblioteca</span></Button>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>

                        <ToastContainer />

                        <div className='container overflow-auto mt-2'>
                            <Table id='tableList' striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center'>#ID</th>
                                        <th className='text-center'>Nome</th>
                                        <th className='text-center'>Cliente</th>
                                        <th className='text-center'>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredBiblioteca.map((biblioteca, index) => (
                                        <tr key={biblioteca.id}>
                                            <td className='text-center'>{biblioteca.id}</td>
                                            <td className='text-center'>{biblioteca.name}</td>
                                            <td className='text-center'>{biblioteca.client_name ?? 'Todos'}</td>
                                            <td className='text-center' id='tdButtonAction'>

                                                {
                                                    userPermissions.some((permission) => permission.name === 'show_biblioteca') ? (
                                                        <Button className='editButton text-bg-warning btnTableMoBile' onClick={() => showbliotecaPage(biblioteca.id)}><FiLayers /> Materiais</Button>
                                                    ) : ''
                                                }

                                                {
                                                    userPermissions.some((permission) => permission.name === 'edit_biblioteca') ? (
                                                        <Button className='editButton btnTableMoBile ms-1 me-2' onClick={() => showEditBibliotecaPage(biblioteca.id)}><BsPencilSquare /> Editar</Button>
                                                    ) : ''
                                                }

                                                {
                                                    userPermissions.some((permission) => permission.name === 'delete_biblioteca') ? (
                                                        <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(biblioteca.id, biblioteca.name)}><FaTrash /> Excluir</Button>
                                                    ) : ''
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Index