import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { HiTemplate } from 'react-icons/hi'
import { FaTrash } from 'react-icons/fa'
import { BsArrowReturnLeft, BsPlusCircle } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cookies from 'js-cookie'
import Stack from 'react-bootstrap/Stack';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Create = () => {
  const history = useNavigate()
  const [name, setName] = useState('')
  const [clients, setClients] = useState([])
  const [clientToAssociate, setClientToAssociate] = useState('')
  const [lojista, setLojista] = useState(false)
  const [layout, setLayout] = useState('')
  const [materiais, setMateriais] = useState([])
  const [materiaisToAssociate, setMateriaisToAssociate] = useState([])
  const [variaveis, setVariaveis] = useState([])
  const customId = 'success-toast-id'

  const createTemplate = async (e) => {
    e.preventDefault()

    if (name === '') {
      toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
    } else if (clientToAssociate === '') {
      toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
    } else if (materiaisToAssociate.length === 0) {
      toast.error('Por favor selecione um material.', { autoClose: 3000 })
    } else if (layout === '') {
      toast.error('Por favor selecione o layout.', { autoClose: 3000 })
    } else {
      await api.post('/api/template/store', {
        name,
        ambienteToAssociate: Cookies.get('ambienteId'),
        clientToAssociate,
        lojista,
        layout,
        materiaisToAssociate,
        variaveis
      }).then(() => {
        setName('')
        toast.success('Template criado com sucesso!', {
          toastId: customId,
          autoClose: 3000,
          onClose: history('/templates'),
        })
      })
    }
  }

  const handleChangeAllClientes = async (e) => {
    setClientToAssociate(e)

    await api.post(`api/materiais/getAllMateriais`, {
      cliente: e,
    }).then((res) => {
      let materiaisData = []

      res.data.materiais.map((material) => {
        materiaisData.push({
          value: material.id,
          label: material.nome,
        })
      })

      setMateriais(materiaisData)
    })
  }

  const handleChangeCliente = async (e) => {
    setClientToAssociate(e)

    if (e.value === 'todos') {
      handleChangeAllClientes(e)
    } else {
      await api.post(`api/materiais/getMateriaisByClientId`, {
        cliente: e,
      }).then((res) => {
        let materiaisData = []

        res.data.materiais.forEach((material) => {
          materiaisData.push({
            value: material.id,
            label: material.nome,
          })
        })

        setMateriais(materiaisData)
      })
    }
  }

  const handleNomeInput = (e, index) => {
    const novosNomes = [...variaveis]
    novosNomes[index].nome = e.target.value
    setVariaveis(novosNomes)
  }

  const handleDadoInput = (e, index) => {
    const novosDados = [...variaveis]
    novosDados[index].dado = e.target.value
    setVariaveis(novosDados)
  }

  const handleSizeInput = (e, index) => {
    const novosDados = [...variaveis]
    novosDados[index].font_size = e.target.value
    setVariaveis(novosDados)
  }

  const handleTipoInput = (e, index) => {
    const novosTipos = [...variaveis]
    novosTipos[index].tipo = e
    novosTipos[index].ordem = index

    setVariaveis(novosTipos)
  }

  const handleListaInput = (e, index) => {
    const novasOpcoes = [...variaveis]
    novasOpcoes[index].lista = e.target.value
    setVariaveis(novasOpcoes)
  }

  const adicionarVariavel = (e) => {
    e.preventDefault()

    setVariaveis([...variaveis, {
      nome: '',
      dado: '',
      tipo: '',
      lista: '',
      font_size: '',
      ordem: 0
    }]);
  }

  useEffect(() => {
    let cancel = false

    async function fetchData() {

      await api.post(`api/client/getClientByAmbienteId`, {
        ambiente: Cookies.get('ambienteId'),
      }).then((res) => {
        if (cancel) return

        let clientsData = [{ label: 'Todos', value: 'todos' }]

        res.data.clients.forEach((client) => {
          clientsData.push({
            value: client.id,
            label: client.name,
          })
        })

        setClients(clientsData)
      })
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <>
      <NavBar />

      <div id="divContainer" className="container">
        <div className="headerContainer editcreateMobile">
          <div className='divheaderTittleMobile'>
            <div id="divHeaderTittle">
              <HiTemplate className="fs-1" />
              <h3 id="tittleH2"> Novo template</h3>
            </div>
          </div>

          <div className='divbtnVoltarMobile'>
            <div>
              <NavLink to="/templates" style={{ textDecoration: 'none' }}>
                <Button id="buttonBack">
                  <BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar
                </Button>
              </NavLink>
            </div>
          </div>
        </div>

        <ToastContainer />

        <div>
          <Form className='mb-5 formGenMobile'>
            <Row className='rowGenForm'>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className='fw-semibold'>Clientes</Form.Label>
                  <Select
                    className='input-group-edit'
                    id="input-group-edit"
                    value={clientToAssociate}
                    onChange={(e) => handleChangeCliente(e)}
                    options={clients}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlid="formBasicName">
                  <Form.Label className='fw-semibold'>Nome</Form.Label>
                  <Form.Control
                    className='input-group-edit'
                    id="input-group-edit"
                    type="text"
                    placeholder="nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='flexDirectionMobile'>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className='fw-semibold'>Layout do template</Form.Label>
                  <Select
                    className='input-group-edit'
                    id="input-group-edit"
                    value={layout}
                    onChange={(e) => setLayout(e)}
                    options={[{ label: 'Vertical', value: 'vertical' }, { label: 'Horizontal', value: 'horizontal' }]}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className='fw-semibold'>Buscar template</Form.Label>
                  <Select
                    className='input-group-edit'
                    id="input-group-edit"
                    value={materiaisToAssociate}
                    onChange={(e) => setMateriaisToAssociate(e)}
                    options={materiais}
                    isMulti={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs lg="2">
                <Button onClick={(e) => adicionarVariavel(e)} className='editButton w-100'><BsPlusCircle /> Nova variável</Button>
              </Col>
              <Col>
                {variaveis.map((variavel, index) => (
                  <Form.Group className='mb-2 d-flex' key={index}>
                    <Stack direction="horizontal" gap={3} className='stackFormMobile'>
                      <div>
                        <Select
                          className='input-group-edit'
                          id="input-group-edit"
                          value={variavel.tipo}
                          onChange={(e) => handleTipoInput(e, index)}
                          options={[{ label: 'Vídeo', value: 1 }, { label: 'Foto', value: 2 }, { label: 'Texto', value: 3 }, { label: 'Lista', value: 4 }]}
                        />
                      </div>
                      <div>
                        <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='Nome' value={variavel.nome} onChange={(e) => handleNomeInput(e, index)} />
                      </div>
                      <div>
                        <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='Variável' value={variavel.dado} onChange={(e) => handleDadoInput(e, index)} />
                      </div>
                      <div>
                        {
                          variavel.tipo.value === 4 ? (
                            <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='Opções (entre;)' value={variavel.lista} onChange={(e) => handleListaInput(e, index)} />
                          ) : ''
                        }
                      </div>
                      <Button className='deleteButton ' onClick={() => setVariaveis([...variaveis.slice(0, index), ...variaveis.slice(index + 1)])}><FaTrash /> Excluir</Button>
                    </Stack>
                  </Form.Group>
                ))}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Form.Check
                  type='switch'
                  id='input-group-edit'
                  checked={lojista}
                  onChange={(e) => setLojista(e.target.checked)}
                  label='Lojista?'
                  style={{ marginTop: '8px', marginBottom: '5px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs lg="2">
                <Button className='btnCreateMobile' onClick={createTemplate} id="buttonNew" type="submit">
                  Criar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
}

export default Create
