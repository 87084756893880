import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Loading from '../../components/LoaderComponent/loader'
import AnimationLogsLottie from './AnimationLogsLottie/AnimationLogsLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const [logs, setLogs] = useState([])
    const [search, setSearch] = useState('')
    const [details, setDetails] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [logsPerPage] = useState(10)
    const indexOfLastLog = currentPage * logsPerPage
    const indexOfFirstLog = indexOfLastLog - logsPerPage
    const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog)
    const nPages = Math.ceil(logs.length / logsPerPage)
    const [loading, setLoading] = useState(true)

    const getLogs = async () => {

        setLoading(true)

        try {
            const [logsResponse] = await Promise.all([
                api.get('/api/getUserActivitiesLogs')
            ])

            setLogs(logsResponse.data.logs);

        } catch (error) {
            console.error('Erro');
        } finally {
            setLoading(false)
        }

        const response = await api.get('/api/getUserActivitiesLogs')

        setLogs(response.data.logs)
    }


    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    const popover = (
        <Popover>
            <Popover.Header as='h3'>Detalhes</Popover.Header>
            <Popover.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '5px' }}>IP:</p>
                    <p>{details.IP}</p>
                </div>

                <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '5px' }}>Navegador:</p>
                    <p>{details.user_agent}</p>
                </div>

                {
                    details.attributes ?
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginRight: '5px' }}>Atributos:</p>
                            <p style={{ maxWidth: '70%' }}>{JSON.stringify(details.attributes)}</p>
                        </div> : ''
                }

                {
                    details.old ?
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginRight: '5px' }}>Antigo:</p>
                            <p style={{ maxWidth: '70%' }}>{JSON.stringify(details.old)}</p>
                        </div> : ''
                }
            </Popover.Body>
        </Popover>
    )

    useEffect(() => {
        getLogs()
    }, [])

    const filteredLogs = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentLogs
        } else {
            return logs.filter(
                (log) =>
                    log.log_name.toLowerCase().includes(lowerSearch) ||
                    log.id.toString().toLowerCase().includes(lowerSearch) ||
                    log.subject_id.toString().toLowerCase().includes(lowerSearch) ||
                    log.causer_name.toLowerCase().includes(lowerSearch) ||
                    log.causer_name.toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, logs, currentLogs])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className='container'>
                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'>
                                <AnimationLogsLottie />
                                <h3 id='tittleH2'>Logs dos Usuários</h3>
                            </div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id='search'>
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder='pesquisar'
                                    aria-label='pesquisar'
                                    aria-describedby='search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Log</th>
                                    <th className='text-center'>Nome Causador</th>
                                    <th className='text-center'>Email Causador</th>
                                    <th className='text-center'>Id Afetado</th>
                                    <th className='text-center'>Entidade Afetada</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredLogs.map((log, index) => (
                                    <tr key={log.id}>
                                        <td className='text-center'>{log.id}</td>
                                        <td className='text-center'>{log.description}</td>
                                        <td className='text-center'>{log.causer_name}</td>
                                        <td className='text-center'>{log.causer_email}</td>
                                        <td className='text-center'>{log.subject_id}</td>
                                        <td className='text-center'>{log.subject_type.split('\\')[2]}</td>
                                        <td className='text-center'>
                                            <OverlayTrigger trigger='click' placement='left' overlay={popover}>
                                                <Button className='editButton btnTableMoBile' onClick={() => setDetails(JSON.parse(log.properties))}>
                                                    <BsPencilSquare /> Detalhes
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className='div_pagination'>
                        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            )}
        </>
    )
}

export default Index
