import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { GrPersonalComputer } from 'react-icons/gr'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Edit = () => {
    const history = useNavigate()
    const [localToAssociate, setLocalToAssociate] = useState('')
    const [gradeToAssociate, setGradeToAssociate] = useState('')
    const [grades, setGrades] = useState([])
    const [name, setName] = useState('')
    const [monitores, setMonitores] = useState('')
    const [layout, setLayout] = useState('')
    const [modelo, setModelo] = useState([])
    const [selectedModelo, setSelectedModelo] = useState('')
    const [patrimonio, setPatrimonio] = useState('')
    const [monitoramento, setMonitoramento] = useState(false)
    const [bloqueio, setBloqueio] = useState(false)
    const [webcam, setWebcam] = useState(false)
    const [dataInstalacao, setDataInstalacao] = useState('')
    const [activated, setActivated] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editMaquina = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (gradeToAssociate === '') {
            toast.error('Por favor selecione uma grade.', { autoClose: 3000 })
        } else if (patrimonio === '') {
            toast.error('Por favor preencha o patrimônio.', { autoClose: 3000 })
        } else if (layout === '') {
            toast.error('Por favor selecione um layout.', { autoClose: 3000 })
        } else if (modelo === '') {
            toast.error('Por favor selecione um modelo.', { autoClose: 3000 })
        } else if (monitores === '') {
            toast.error('Por favor selecione a quantidade de monitores.', { autoClose: 3000 })
        } else if (dataInstalacao === '') {
            toast.error('Por favor selecione a data de instalação.', { autoClose: 3000 })
        } else {
            await api.put(`/api/maquina/${id}`, {
                gradeToAssociate,
                name,
                monitores,
                layout,
                modelo: selectedModelo['value'],
                patrimonio,
                monitoramento,
                bloqueio,
                webcam,
                dataInstalacao,
                ativo: activated,
            }).then(() => {
                setName('')
                setMonitores('')
                setLayout('')
                setModelo('')
                setPatrimonio('')
                setMonitoramento('')
                setBloqueio('')
                setWebcam('')
                setDataInstalacao('')
                toast.success('Máquina editada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history(`/maquinas/${localToAssociate.value}`) })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/maquina/${id}`).then((res) => {
                if (cancel) return
                
                setName(res.data.maquina.name)
                setMonitores(res.data.maquina.monitores)
                setLayout(res.data.maquina.layout)
                setModelo(res.data.maquina.modelo ? res.data.maquina.modelo : "")
                setPatrimonio(res.data.maquina.patrimonio)
                setMonitoramento(res.data.maquina.monitoramento)
                setBloqueio(res.data.maquina.bloqueio)
                setWebcam(res.data.maquina.webcam)
                setDataInstalacao(res.data.maquina.data_instalacao ? res.data.maquina.data_instalacao.split(' ')[0] : "")
                setActivated(res.data.maquina.ativo)
                setLocalToAssociate(res.data.maquinaLocal)
                setSelectedModelo(res.data.maquina.modelo ? { value: res.data.maquina.modelo, label: res.data.maquina.modelo_maquina.nome } : "")

                let gradesData = []

                res.data.grades.forEach(grade => {
                    gradesData.push({
                        value: grade.id,
                        label: grade.name
                    })
                })

                setGrades(gradesData)
                setGradeToAssociate(res.data.maquinaGrade)
            })

            const res = await api.get('/api/modeloMaquina/index')
            if (cancel) return

            let modelosData = []

            res.data.modelos.forEach(modelos => {
                modelosData.push({
                    value: modelos.id,
                    label: modelos.nome
                })
            })

            setModelo(modelosData)
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    const optionsLayout = ['Vertical', 'Horizontal'];
    const optionsMonitor = ['1', '2', '3', '4', '5'];

    const handleChangeModelo = (selectedOption) => {
        setSelectedModelo(selectedOption)
    }

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer'>
                    <div>
                        <div id='divHeaderTittle'><GrPersonalComputer className='fs-1' /><h3 id='tittleH2'> Edição de máquina</h3></div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div>
                            <Button onClick={() => history(`/maquinas/${localToAssociate.value}`)} id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form>
                        <Form.Group id='input-group-edit' className='mb-3' controlid='formBasicName'>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>

                        <Form.Group id='input-group-edit' className='mb-3'>
                            <Form.Label>Grade</Form.Label>
                            <Select value={gradeToAssociate} onChange={(e) => setGradeToAssociate(e)} options={grades} />
                        </Form.Group>

                        <Form.Group id='input-group-edit' className='mb-3' controlid='formBasicName'>
                            <Form.Label>Patrimônio</Form.Label>
                            <Form.Control type='text' placeholder='patrimonio' value={patrimonio} onChange={(e) => setPatrimonio(e.target.value)} />
                        </Form.Group>

                        <Form.Check
                            id='input-group-edit'
                            type='switch'
                            checked={monitoramento}
                            onChange={(e) => setMonitoramento(e.target.checked)}
                            label='Monitoramento'
                            style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                        />

                        <Form.Check
                            id='input-group-edit'
                            type='switch'
                            checked={bloqueio}
                            onChange={(e) => setBloqueio(e.target.checked)}
                            label='Bloqueio'
                            style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                        />

                        <Form.Check
                            id='input-group-edit'
                            type='switch'
                            checked={webcam}
                            onChange={(e) => setWebcam(e.target.checked)}
                            label='Webcam'
                            style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                        />

                        <Form.Group id='input-group-edit' className='mb-3' controlId='formBasicSector'>
                            <Form.Label>Monitores</Form.Label>
                            <Form.Select
                                name='monitores'
                                value={monitores}
                                onChange={(e) =>
                                    setMonitores(e.target.value)
                                }>
                                <option value="">Selecione uma opção</option>
                                {optionsMonitor.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group id='input-group-edit' className='mb-3' controlId='formBasicSector'>
                            <Form.Label>Layout da Máquina</Form.Label>
                            <Form.Select
                                name='layout'
                                value={layout}
                                onChange={(e) =>
                                    setLayout(e.target.value)
                                }>
                                <option value="">Selecione uma opção</option>
                                {optionsLayout.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group id='input-group-edit' className='mb-3' controlId='formBasicSector'>
                            <Form.Label>Modelos de Máquina</Form.Label>
                            <Select
                                className='shadowCustom'
                                id="input-group-edit"
                                value={selectedModelo}
                                onChange={(e) => handleChangeModelo(e)}
                                options={modelo}
                            />
                        </Form.Group>

                        <Form.Group id='input-group-edit' className='mb-3' controlId='exampleForm.ControlDatePicker1'>
                            <Form.Label>Data de instalação</Form.Label>
                            <Form.Control
                                name='dataInstalacao'
                                type='date'
                                value={dataInstalacao}
                                onChange={(e) =>
                                    setDataInstalacao(e.target.value)
                                }
                            />
                        </Form.Group>

                        <Button onClick={editMaquina} id='buttonNew' type='submit'>
                            Salvar
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit