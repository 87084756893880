import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import { useNavigate, useParams } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsArrowReturnLeft } from 'react-icons/bs'
import { IoReload } from "react-icons/io5"
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form, Button } from 'react-bootstrap'
import AnimationMateriaisLottie from './AnimationMateriaisLottie/AnimationMateriaisLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'
import Loading from '../../components/LoaderComponent/loader'

const Index = () => {
    const history = useNavigate()
    const [material, setMaterial] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [thumbShow, setThumbShow] = useState(false)
    const [materialIdToDelete, setMaterialIdToDelete] = useState('')
    const [materialNameToDelete, setMaterialNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [materialPerPage] = useState(10)
    const indexOfLastMaterial = currentPage * materialPerPage
    const indexOfFirstMaterial = indexOfLastMaterial - materialPerPage
    const currentMaterial = material.slice(indexOfFirstMaterial, indexOfLastMaterial)
    const nPages = Math.ceil(material.length / materialPerPage)
    const [loading, setLoading] = useState(true);

    let { id } = useParams()

    const getMaterial = async () => {

        setLoading(true);

        try {
            const [userPermissionsResponse, materialResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get(`/api/material/index/${id}`)
            ]);

            setUserPermissions(userPermissionsResponse.data.permissions);
            setMaterial(materialResponse.data.material);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });

        } finally {
            setLoading(false);
        }

        const response = await api.get(`/api/material/index/${id}`)
        setMaterial(response.data.material)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (materialId, materialName) => {
        setModalShow(true)
        setMaterialIdToDelete(materialId)
        setMaterialNameToDelete(materialName)
    }

    const thumbShowModalbtn = (materialId, materialName) => {
        setThumbShow(true)
        setMaterialIdToDelete(materialId)
        setMaterialNameToDelete(materialName)
    }

    const deleteMaterial = async (materialId) => {
        await api.delete(`/api/material/${materialId}`).then(() => {
            getMaterial()
            setModalShow(false)
            toast.success('Material deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteMaterialConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Material</h4>
                    <p>
                        Você tem certeza de que deseja remover este material <span className='d-flex justify-content-center mb-0'>{props.materialname} ?</span>
                    </p>
                </Modal.Body>

                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteMaterial(props.materialid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function ThumbShowModal(props) {
        const { materialname, materialid } = props;
        const selectedMaterial = material.find(material => material.id_material === materialid);
        if (!selectedMaterial) {
            return null; // Ou renderizar uma mensagem de erro ou retornar null
        }
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                size='xl'
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <h4>
                                <span className='modalHeaderFP d-flex justify-content-center mb-0'>{materialname}</span>
                            </h4>
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body key={selectedMaterial.id_material} id='modalBody'>
                    {selectedMaterial.tipo === 'imagem' ? (
                        <img id='thumb' style={{ width: '80%' }} src={`https://publisherdev.nyc3.cdn.digitaloceanspaces.com/uploads/materiais/${selectedMaterial.caminho}/${selectedMaterial.arquivo}`} alt="Imagem Preview" />
                    ) : selectedMaterial.tipo === 'video' ? (
                        <video id='thumb' style={{ width: '100%' }} src={`https://publisherdev.nyc3.cdn.digitaloceanspaces.com/uploads/materiais/${selectedMaterial.caminho}/${selectedMaterial.arquivo}`} controls></video>
                    ) : selectedMaterial.tipo === 'zip' ? (
                        <p>Arquivo zip. Não é possível exibir um preview.</p>
                    ) : (
                        <p>Tipo de arquivo não suportado.</p>
                    )}
                </Modal.Body>
            </Modal>
        )
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getMaterial()
        // eslint-disable-next-line
    }, [])

    const filteredMaterial = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentMaterial
        } else {
            return material.filter((material) => material.nome.toLowerCase().includes(lowerSearch) || material.id_material.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, material, currentMaterial])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div id='divContainer' className='container'>
                    <DeleteMaterialConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        materialid={materialIdToDelete}
                        materialname={materialNameToDelete}
                    />
                    <ThumbShowModal
                        show={thumbShow}
                        onHide={() => setThumbShow(false)}
                        materialname={materialNameToDelete}
                        materialid={materialIdToDelete}
                    />

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'><AnimationMateriaisLottie /><h3 id='tittleH2'>Materiais</h3></div>
                        </div>

                        <div id='div-input-group' className='div-input-group '>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder="pesquisar"
                                    aria-label="pesquisar"
                                    aria-describedby="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {
                                userPermissions.some((permission) => permission.name === 'store_material') ? (
                                    <div className='btnVoltarMobile'>
                                        <Button className='borderbtnCreateEdit btnMoreMat' onClick={() => history(`/materialCreate/${id}`)} id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Novo Material</span></Button>
                                    </div>
                                ) : ''
                            }

                            {
                                userPermissions.some((permission) => permission.name === 'unzip_material') ? (
                                    <div className='divButtonBack2 ms-3'>
                                        <Button className='borderbtnRed' onClick={() => api.get('/api/massiveUnzip')} ><IoReload className='me-2' />Unzip Templates</Button>
                                    </div>
                                ) : ''
                            }

                            <div className='divButtonBack2'>
                                <Button className='borderbtnRed' onClick={() => history('/bibliotecas')} id='buttonBack2'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </div>
                        </div>


                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Nome</th>
                                    <th className='text-center'>Tipo</th>
                                    <th className='text-center'>Thumb</th>
                                    <th className='text-center'>Resolução</th>
                                    <th className='text-center'>Tamanho</th>
                                    <th className='text-center'>Duração</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredMaterial.map((material, index) => (
                                    <tr key={material.id_material}>
                                        <td className='text-center'>{material.id_material}</td>
                                        <td className='w-25 text-center'>{material.nome}</td>
                                        <td className='text-center'>{material.tipo}</td>
                                        <td className='w-25 text-center' onClick={() => thumbShowModalbtn(material.id_material, material.nome, material.arquivo)}>
                                            {material.tipo === 'imagem' ? (
                                                <img className='tdCustomThumbShow' id='thumb' style={{ maxHeight: '60px' }} src={`https://publisherdev.nyc3.cdn.digitaloceanspaces.com/uploads/materiais/${material.caminho}/${material.arquivo}`} alt="Imagem Preview" />
                                            ) : material.tipo === 'video' ? (
                                                <video className='tdCustomThumbShow' id='thumb' style={{ maxHeight: '80px' }} src={`https://publisherdev.nyc3.cdn.digitaloceanspaces.com/uploads/materiais/${material.caminho}/${material.arquivo}`} ></video>
                                            ) : material.tipo === 'zip' ? (
                                                <p>Arquivo zip. Não é possível exibir um preview.</p>
                                            ) : (
                                                <p>Tipo de arquivo não suportado.</p>
                                            )}
                                        </td>
                                        <td className='text-center'>{material.resolucao}p</td>
                                        <td className='text-center'>{material.tamanho_bytes}mb</td>
                                        <td className='text-center'>{material.duracao}</td>
                                        <td className='text-center'>
                                            <div className='d-flex tdBtnTableMobile'>
                                                {
                                                    userPermissions.some((permission) => permission.name === 'edit_maquina') ? (
                                                        <Button className='editButton btnTableMoBile me-2' onClick={() => history(`/material/${id}/${material.id_material}`)}><BsPencilSquare /> Editar</Button>
                                                    ) : ''
                                                }

                                                {
                                                    userPermissions.some((permission) => permission.name === 'delete_maquina') ? (
                                                        <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(material.id_material, material.nome)}><FaTrash /> Excluir</Button>
                                                    ) : ''
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
        </>
    )
}

export default Index