import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { HiTemplate } from 'react-icons/hi'
import { FaTrash } from 'react-icons/fa'
import { BsArrowReturnLeft, BsPlusCircle } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cookies from 'js-cookie'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Edit = () => {
  const history = useNavigate()
  const [name, setName] = useState('')
  const [clients, setClients] = useState([])
  const [clientToAssociate, setClientToAssociate] = useState('')
  const [lojista, setLojista] = useState(false)
  const [layout, setLayout] = useState('')
  const [materiais, setMateriais] = useState([])
  const [materiaisToAssociate, setMateriaisToAssociate] = useState([])
  const [variaveis, setVariaveis] = useState([])
  const customId = 'success-toast-id'

  let { id } = useParams()

  const createTemplate = async (e) => {
    e.preventDefault()

    if (name === '') {
      toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
    } else if (clientToAssociate === '') {
      toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
    } else if (materiaisToAssociate.length === 0) {
        toast.error('Por favor selecione um material.', { autoClose: 3000 })
    } else if (layout === '') {
        toast.error('Por favor selecione o layout.', { autoClose: 3000 })
    } else {
      await api.put(`/api/template/${id}`, {
        name,
        ambienteToAssociate: Cookies.get('ambienteId'),
        clientToAssociate,
        lojista,
        layout,
        materiaisToAssociate,
        variaveis
      }).then(() => {
        setName('')
        toast.success('Template criado com sucesso!', {
          toastId: customId,
          autoClose: 3000,
          onClose: history('/templates'),
        })
      })
    }
  }

  const handleChangeAllClientes = async (e) => {
    setClientToAssociate(e)

    await api.post(`api/materiais/getAllMateriais`, {
      cliente: e,
    }).then((res) => {
      let materiaisData = []

      res.data.materiais.map((material) => {
        materiaisData.push({
          value: material.id,
          label: material.nome,
        })
      })

      setMateriais(materiaisData)
    })
  }

  const handleChangeCliente = async (e) => {
    setClientToAssociate(e)

    if (e.value === 'todos') {
      handleChangeAllClientes(e)
    } else {
    await api.post(`api/materiais/getMateriaisByClientId`, {
        cliente: e,
      }).then((res) => {
        let materiaisData = []
  
        res.data.materiais.forEach((material) => {
          materiaisData.push({
            value: material.id,
            label: material.nome,
          })
        })

        setMateriais(materiaisData)
      })
    }
  }

  const handleNomeInput = (e, index) => {
      const novosNomes = [...variaveis]
      novosNomes[index].nome = e.target.value
      setVariaveis(novosNomes)
  }

  const handleDadoInput = (e, index) => {
      const novosDados = [...variaveis]
      novosDados[index].dado = e.target.value
      setVariaveis(novosDados)
  }

  const handleTipoInput = (e, index) => {
    const novosTipos = [...variaveis]
    novosTipos[index].tipo = e
    novosTipos[index].ordem = index

    setVariaveis(novosTipos)
  }

  const handleSizeInput = (e, index) => {
    const novosDados = [...variaveis]
    novosDados[index].font_size = e.target.value
    setVariaveis(novosDados)
  }

  const handleListaInput = (e, index) => {
    const novasOpcoes = [...variaveis]
    novasOpcoes[index].lista = e.target.value
    setVariaveis(novasOpcoes)
  }

  const adicionarVariavel = (e) => {
    e.preventDefault()

    setVariaveis([...variaveis, {
      nome: '',
      dado: '',
      tipo: '',
      lista: '',
      font_size: '',
      ordem: 0
    }]);
}

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      await api.get(`api/template/${id}`).then((res) => {
        if (cancel) return

        setClients(res.data.clientes)
        setClientToAssociate({
            label: res.data.cliente.name,
            value: res.data.cliente.id
        })

        setName(res.data.template.nome)

        setLojista(res.data.template.lojista)

        setLayout({ label: res.data.template.formato.charAt(0).toUpperCase() + res.data.template.formato.slice(1).toLowerCase(), value: res.data.template.formato })

        setMateriais(res.data.materiais)

        setMateriaisToAssociate(res.data.materiaisTemplate)

        setVariaveis(res.data.variaveis)

      })

      await api.post(`api/client/getClientByAmbienteId`, {
        ambiente: Cookies.get('ambienteId'),
      }).then((res) => {
        if (cancel) return

        let clientsData = [{ label: 'Todos', value: 'todos' }]
  
        res.data.clients.forEach((client) => {
          clientsData.push({
            value: client.id,
            label: client.name,
          })
        })
  
        setClients(clientsData)
      })
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [id])

  return (
    <>
      <NavBar />

      <div id="divContainer" className="container">
        <div className="headerContainer">
          <div>
            <div id="divHeaderTittle">
              <HiTemplate className="fs-1" />
              <h3 id="tittleH2"> Edição de template</h3>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div>
              <NavLink to="/templates" style={{ textDecoration: 'none' }}>
                <Button id="buttonBack">
                  <BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar
                </Button>
              </NavLink>
            </div>
          </div>
        </div>

        <ToastContainer />

       <div>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Clientes</Form.Label>
                  <Select
                    id="input-group-edit"
                    value={clientToAssociate}
                    onChange={(e) => handleChangeCliente(e)}
                    options={clients}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlid="formBasicName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    id="input-group-edit"
                    type="text"
                    placeholder="nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Layout do template</Form.Label>
                  <Select
                    id="input-group-edit"
                    value={layout}
                    onChange={(e) => setLayout(e)}
                    options={[{ label: 'Vertical', value: 'vertical' }, { label: 'Horizontal', value: 'horizontal' }]}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Buscar template</Form.Label>
                  <Select
                    id="input-group-edit"
                    value={materiaisToAssociate}
                    onChange={(e) => setMateriaisToAssociate(e)}
                    options={materiais}
                    isMulti={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Button onClick={(e) => adicionarVariavel(e)} className='editButton'><BsPlusCircle /> Nova variável</Button>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  {variaveis.map((variavel, index) => (
                    <div key={index} style={{ display: 'flex', marginBottom: '5px' }}>
                      <div style={{ marginRight: '5px', width: '100px' }}>
                        <Select
                          id="input-group-edit"
                          value={variavel.tipo}
                          
                          onChange={(e) => handleTipoInput(e, index)}
                          options={[{ label: 'Vídeo', value: 1 }, { label: 'Foto', value: 2 }, { label: 'Texto', value: 3 }, { label: 'Lista', value: 4 }]}
                        />
                      </div>

                      <Form.Control id='input-group-edit' style={{ width: '13rem', marginRight: '5px' }}  type='text' placeholder='Nome' value={variavel.nome} onChange={(e) => handleNomeInput(e, index)} />
                      <Form.Control id='input-group-edit' style={{ width: '13rem', marginRight: '5px' }}  type='text' placeholder='Variável' value={variavel.dado} onChange={(e) => handleDadoInput(e, index)} />

                      {
                        variavel.tipo.value === 4 ? (
                          <Form.Control id='input-group-edit' style={{ width: '13rem', marginRight: '5px' }}  type='text' placeholder='Opções (entre;)' value={variavel.lista} onChange={(e) => handleListaInput(e, index)} />
                        ) : ''
                      }

                      {
                        variavel.tipo.value === 3 ? (
                          <Form.Control id='input-group-edit' min={0} style={{ width: '13rem', marginRight: '5px' }}  type='number' placeholder='Tamanho da fonte' value={variavel.font_size} onChange={(e) => handleSizeInput(e, index)} />
                        ) : ''
                      }

                      <Button className='deleteButton ' onClick={() => setVariaveis([...variaveis.slice(0, index), ...variaveis.slice(index + 1)])}><FaTrash /> Excluir</Button>
                    </div>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type='switch'
                  id='input-group-edit'
                  checked={lojista}
                  onChange={(e) => setLojista(e.target.checked)}
                  label='Lojista?'
                  style={{ marginTop: '8px', marginBottom: '5px' }}
                />
              </Col>
              <Col xs={2}>
                <Button onClick={createTemplate} id="buttonNew" type="submit">
                  Criar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
}

export default Edit
