import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { AiFillCloud } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('') // Garantindo valor inicial
    const [token, setToken] = useState('') // Garantindo valor inicial
    const [color, setColor] = useState('#000000') // Cor inicial definida
    const [logoFile, setLogoFile] = useState(null) // Valor inicial null para arquivo
    const [activated, setActivated] = useState(false) // Booleano padrão
    const [approval, setApproval] = useState(false) // Booleano padrão
    const [lojista, setLojista] = useState(false) // Booleano padrão
    const [twoFactorAuth, setTwoFactorAuth] = useState(false) // Booleano padrão
    const [selectedConteudo, setSelectedConteudo] = useState('') // Garantindo valor inicial string vazia
    const [conteudosLojista, setConteudosLojista] = useState([]) // Array vazio como valor inicial
    const customId = 'success-toast-id'
    let { id } = useParams()

    const handleColorChange = (event) => {
        setColor(event.target.value)
    }

    const editAmbiente = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        if (logoFile) {
            formData.append('logo', logoFile)
        }

        formData.append('name', name)
        formData.append('token', token)
        formData.append('color_navbar', color)
        formData.append('ativo', activated ? 1 : 0)
        formData.append('approval', approval ? 1 : 0)
        formData.append('id_conteudo_lojista', selectedConteudo)
        formData.append('lojista', lojista ? 1 : 0)
        formData.append('twoFactorAuth', twoFactorAuth ? 1 : 0)

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else {
            await api.post(`/api/ambiente/update/${id}`, formData).then((res) => {
                setName('')
                toast.success('Ambiente editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/ambientes') })
            }).catch((error) => {
                toast.error('Erro ao editar ambiente.', { toastId: customId, autoClose: 3000 })
                console.error(error)
            })
        }
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/ambiente/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.ambiente.name || '') // Garantir que name nunca seja undefined
                setToken(res.data.ambiente.token || '') // Garantir que token nunca seja undefined
                setColor(res.data.ambiente.color_navbar || '#000000') // Cor padrão se undefined
                setActivated(!!res.data.ambiente.ativo) // Forçar booleano
                setApproval(!!res.data.ambiente.approvals) // Forçar booleano
                setLojista(!!res.data.ambiente.lojista) // Forçar booleano
                setTwoFactorAuth(!!res.data.ambiente.twoFactorAuth) // Forçar booleano
                setSelectedConteudo(res.data.ambiente.id_conteudo_lojista || '') // Garantir que nunca seja undefined
            })

            api.get(`api/getConteudosByAmbienteId`).then(res => {
                if (cancel) return

                setConteudosLojista(res.data.conteudos || []) // Array vazio se não houver dados
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AiFillCloud className='fs-1' /><h3 id='tittleH2'> Edição de ambiente</h3></div>
                    </div>
                    <div className='divbtnVoltarMobile'>
                        <NavLink to='/ambientes' style={{ textDecoration: 'none' }}>
                            <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </NavLink>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='formGenMobile'>
                        <Row>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='text'
                                        placeholder='nome'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlid='formBasicToken'>
                                    <Form.Label className='fw-semibold'>Token</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='text'
                                        placeholder='token'
                                        value={token}
                                        onChange={(e) => setToken(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlId="logo_navbar">
                                    <Form.Label className='fw-semibold'>Logo navbar:</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='file'
                                        name='logo'
                                        onChange={(e) => setLogoFile(e.target.files[0])}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlId="color_navbar">
                                    <Form.Label className='fw-semibold'>Cor navbar:</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='color'
                                        value={color}
                                        onChange={handleColorChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='activeAmbiente'
                                        checked={activated}
                                        onChange={(e) => setActivated(e.target.checked)}
                                        label='Ativar/Desativar'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='approval'
                                        checked={approval}
                                        onChange={(e) => setApproval(e.target.checked)}
                                        label='Aprovações'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='twoFactorAuth'
                                        checked={twoFactorAuth}
                                        onChange={(e) => setTwoFactorAuth(e.target.checked)}
                                        label='Autenticação de dois fatores?'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='lojista'
                                        checked={lojista}
                                        onChange={(e) => setLojista(e.target.checked)}
                                        label='Lojista'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            {
                                lojista ? (
                                    <Form.Group className='mb-3 col-3' controlId='formBasicConteudo'>
                                        <Form.Label className='fw-semibold'>Conteúdo</Form.Label>
                                        <Form.Select
                                            className="fw-medium shadowCustom"
                                            id='input-group-edit'
                                            name='conteúdo'
                                            value={selectedConteudo || ''} // Garantir que sempre tenha valor
                                            onChange={(e) => setSelectedConteudo(e.target.value)}
                                        >
                                            <option value="">Selecione...</option>
                                            {conteudosLojista.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                ) : null
                            }

                            <Row>
                                <Col xs={2}>
                                    <Button onClick={editAmbiente} id='buttonNew' type='submit'>
                                        Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit
