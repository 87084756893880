import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'

import AnimationTemplatesLottie from './AnimationTemplatesLottie/AnimationTemplatesLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Index = () => {
    const history = useNavigate()
    const [templates, setTemplates] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [templateIdToDelete, setTemplateIdToDelete] = useState('')
    const [templateNameToDelete, setTemplateNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [templatesPerPage] = useState(10)
    const indexOfLastTemplate = currentPage * templatesPerPage
    const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage
    const currentTemplates = templates.slice(indexOfFirstTemplate, indexOfLastTemplate)
    const nPages = Math.ceil(templates.length / templatesPerPage)

    const getTemplates = async () => {
        const response = await api.get('/api/templateDND/index')
        setTemplates(response.data.templates)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteTemplateConfirmationModal = (templateId, templateName) => {
        setModalShow(true)
        setTemplateIdToDelete(templateId)
        setTemplateNameToDelete(templateName)
    }

    const deleteTemplate = async (templateId) => {
        await api.delete(`/api/templateDND/${templateId}`).then(() => {
            getTemplates()
            setModalShow(false)
            toast.success('Template deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteTemplateConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id="modalHeader" closeButton>
                    <div id="divModalTitle">
                        <Modal.Title id="modalTitle">
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id="modalBody">
                    <h4>Exclusão de template</h4>
                    <p>
                        Você tem certeza de que deseja remover este template <span className='d-flex justify-content-center mb-0'>{props.templatename} ?</span>
                    </p>
                </Modal.Body>

                <Modal.Footer id="modalFooter">
                    <Button id="yesButton" onClick={() => deleteTemplate(props.templateid)}>
                        Sim
                    </Button>
                    <Button id="noButton" onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditTemplatePage = (templateId) => {
        history(`/template/padrao/edit/${templateId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };


    useEffect(() => {
        getTemplates()
    }, [])

    const filteredTemplates = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentTemplates
        } else {
            return templates.filter(
                (template) =>
                    template.nome.toLowerCase().includes(lowerSearch) ||
                    template.client_name.toLowerCase().includes(lowerSearch) ||
                    template.id.toString().toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, templates, currentTemplates])

    return (
        <>
            <NavBar />

            <div id='divContainer' className="container">
                <DeleteTemplateConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    templateid={templateIdToDelete}
                    templatename={templateNameToDelete}
                />

                <div className="headerContainer">
                    <div>
                        <div id="divHeaderTittle"><AnimationTemplatesLottie /><h3 id="tittleH2">Templates</h3></div>
                    </div>

                    <div id='div-input-group'>
                        <InputGroup id="input-group">
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {userPermissions.some((permission) => permission.name === 'store_template') ? (
                            <NavLink to="/template/padrao/create" style={{ textDecoration: 'none' }}>
                                <Button id="buttonNew">
                                    <AiOutlinePlusCircle id="iconButtonNew" /> Novo Template
                                </Button>
                            </NavLink>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Table id="tableList" striped bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">#ID</th>
                                <th className="text-center">Nome</th>
                                <th className="text-center">Cliente</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTemplates.map((template, index) => (
                                <tr key={template.id} className={template.ativo ? '' : 'table-danger'}>
                                    <td className="text-center">{template.id}</td>
                                    <td className="text-center">{template.nome}</td>
                                    <td className="text-center">{template.client_name}</td>
                                    <td className="text-center">
                                        {userPermissions.some((permission) => permission.name === 'edit_template') ? (
                                            <Button className="editButton me-2" onClick={() => showEditTemplatePage(template.id)}>
                                                <BsPencilSquare /> Editar
                                            </Button>
                                        ) : (
                                            ''
                                        )}

                                        {userPermissions.some((permission) => permission.name === 'delete_template') ? (
                                            <Button
                                                className="deleteButton"
                                                onClick={() => showDeleteTemplateConfirmationModal(template.id, template.nome)}
                                            >
                                                <FaTrash /> Excluir
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            </div>
        </>
    )
}

export default Index
