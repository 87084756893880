import React, { useState, useEffect } from 'react'
import interact from 'interactjs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FaTrash } from 'react-icons/fa'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Select from 'react-select'
import { AiOutlineVideoCameraAdd, AiOutlineFontSize } from "react-icons/ai";
import { LuImagePlus } from "react-icons/lu";
import { MdOutlineTextIncrease } from "react-icons/md";
import { MdFormatListNumbered } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { BiCalendarWeek } from "react-icons/bi";
import { LuClock } from "react-icons/lu";
import { MdEuroSymbol } from "react-icons/md";
import { BiDollar } from "react-icons/bi";
import { FaTemperatureArrowDown } from "react-icons/fa6";
import { FaTemperatureArrowUp } from "react-icons/fa6";
import { PiSelectionBackgroundDuotone } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";
import { LuTextCursorInput } from "react-icons/lu";
import { TbLayoutGridAdd } from "react-icons/tb";
import { MdSaveAlt, MdOutlineFormatColorFill } from "react-icons/md";
import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaAlignLeft, FaAlignCenter, FaAlignRight, FaAlignJustify, FaFont } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import { RxLetterCaseLowercase, RxLetterSpacing, RxLetterCaseUppercase, RxFontFamily } from "react-icons/rx";
import { BiFontColor } from "react-icons/bi";
import { IoText } from "react-icons/io5";
import { CgSandClock } from "react-icons/cg";
import '../styleComponents/styleTemplatedndArea.css'
import '../styleComponents/styleModalTemplateDND.css'
import { ChromePicker } from 'react-color';
import { FaRegTrashAlt } from "react-icons/fa";

const DragAndDropArea = () => {
  const [items, setItems] = useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const [selectedBackground, setSelectedBackground] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [backgrounds, setBackgrounds] = useState([])
  const [modelOptions, setModelOptions] = useState([])
  const [doSpaces, setDoSpaces] = useState('')
  const customId = 'success-toast-id'
  const [bibliotecas, setBibliotecas] = useState([])
  const [bibliotecaToAssociate, setBibliotecaToAssociate] = useState('')
  const history = useNavigate()
  const [backgroundContent, setBackgroundContent] = useState('')
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState('rgba(0, 0, 0, 1)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const handleSubmit = () => {
    const convertedItems = convertItemsForBackend(items)
    if (templateName === '') {
      toast.error('Por favor preencha o campo de Nome Template.', { autoClose: 3000 })
    } else if (selectedBackground === '') {
      toast.error('Por favor escolha um plano de fundo.', { autoClose: 3000 })
    } else if (bibliotecaToAssociate.value === '') {
      toast.error('Por favor selecione uma biblioteca.', { autoClose: 3000 })
    } else {
      setIsSubmitting(true);
      api.post('/api/generate-zip', {
        items: convertedItems,
        background: selectedBackground,
        templateName: templateName,
        biblioteca: bibliotecaToAssociate
      })
        .then(response => {
          toast.success('Template criado com sucesso!', {
            toastId: customId,
            autoClose: 3000,
            onClose: () => {
              setIsSubmitting(false); // Desativa o estado de carregamento
              history('/template/padrao/index'); // Redireciona após o sucesso
            },
          });
        })
        .catch(error => {
          toast.error('Erro ao criar template', { toastId: customId, autoClose: 3000 });
          console.error('Erro ao enviar dados:', error);
          setIsSubmitting(false); // Desativa o estado de carregamento em caso de erro
        });
    }
  }

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      await api.get(`api/materiais/getMateriais`)
        .then(response => {
          setBackgrounds(response.data.materiais)
          setDoSpaces(response.data.spaces)
          setSelectedBackground({ type: response.data.materiais[0].type, url: `${response.data.spaces}${response.data.materiais[0].arquivo}` })

          if (response.data.materiais[0].type === 'video') {
            setBackgroundContent(`
            <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover; background-color: transparent!important;">
              <source src="${response.data.spaces}${response.data.materiais[0].arquivo}" type="video/mp4"/>
              Seu navegador não suporta vídeos.
            </video>
          `)
          } else {
            setBackgroundContent(`
            <div style="width: 100%; height: 100%; background-image: url(${response.data.spaces}${response.data.materiais[0].arquivo}); background-size: cover; background-position: center;"></div>
          `)
          }
        })
        .catch(error => console.error("Falha ao carregar backgrounds:", error))


        await api.post(`api/biblioteca/getBibliotecaByAmbienteId`, {
          ambiente: Cookies.get('ambienteId'),
        }).then((res) => {
          if (cancel) return
  
          let bibliotecasData = [{ label: 'Selecione...', value: '' }]
  
          res.data.bibliotecas.forEach((biblioteca) => {
            bibliotecasData.push({
              value: biblioteca.id,
              label: biblioteca.name,
            })
          })
  
          setBibliotecaToAssociate(bibliotecasData[0])
          setBibliotecas(bibliotecasData)
        })

      await api.get(`api/templateModels`).then((res) => {
        if (cancel) return

        setModelOptions(res.data.models)
      })
    }

    const adjustDragAndDropAreaSize = () => {
      const area = document.getElementById('dragAndDropArea');
      if (!area) return;

      const container = area.parentElement;
      const containerWidth = container.clientWidth;
      const fullHdAspectRatio = 1920 / 1080;

      // Defina a largura para ocupar o máximo possível do container
      let newWidth = containerWidth;

      // Calcule a altura correspondente à largura mantendo a proporção 16:9
      let newHeight = newWidth / fullHdAspectRatio;

      // Ajusta o tamanho da área para manter a proporção e ocupar o máximo de espaço
      area.style.width = `${newWidth}px`;
      area.style.height = `${newHeight}px`;
    };


    adjustDragAndDropAreaSize()

    window.addEventListener('resize', adjustDragAndDropAreaSize)

    window.removeEventListener('resize', adjustDragAndDropAreaSize)

    interact('.resize-drag')
      .draggable({
        onmove: window.dragMoveListener,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),
        ],
      })
      .on('dragmove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemPosition(index, event.dx, event.dy)
      })
      .on('resizemove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemSize(index, event.rect, event.deltaRect)
      })

    function updateItemPosition(index, dx, dy) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              left: item.left + dx,
              top: item.top + dy,
            }
            : item,
        ),
      )
    }

    function updateItemSize(index, rect, deltaRect) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              width: rect.width,
              height: rect.height,
              left: item.left + deltaRect.left,
              top: item.top + deltaRect.top,
            }
            : item,
        ),
      )
    }

    window.dragMoveListener = function (event) {
      const target = event.target
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  const handleChangeBiblioteca = async (e) => {
    setBibliotecaToAssociate(e)
  }

  const removeSelectedItem = () => {
    if (selectedItemIndex !== null) {
      setItems(items.filter((_, index) => index !== selectedItemIndex))
      setSelectedItemIndex(null)
    }
  }

  const itemExists = (type) => items.some(item => item.tipo === type);

  const addItem = (type) => {
    setItems([...items,
    {
      nome: `${type}_${items.length}`,
      tipo: type,
      left: 0,
      top: 0,
      width: 200,
      height: 100,
      fontColor: '#000000',
      fadeIn: 1,
      fadeOut: 30,
      fontSize: 30,
      lista: null,
      textAlign: 'left',
      backgroundColor: 'rgba(171, 171, 171, 0)',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      textTransform: 'none',
      letterSpacing: 'normal',
      textShadow: 'none',
      fontVariant: 'normal',
      fontFamily: 'Arial',
    }
    ])
  }

  const handleColorChange = (color) => {
    setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    updateSelectedItem({ backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` });
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleItemClick = (index) => {
    setSelectedItemIndex(index)
    setColor(items[index].backgroundColor || 'rgba(0, 0, 0, 1)');
  }

  const handleChangeBackground = (e) => {
    if (e.target.value !== '') {
      const bg = backgrounds.find(bg => `${doSpaces}${bg.caminho}/${bg.arquivo}` === e.target.value)

      if (bg) {
        setSelectedBackground({ type: bg.type, url: e.target.value })
      }

      if (bg.type === 'video') {
        setBackgroundContent(`
          <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover; background-color: transparent!important;">
            <source src="${e.target.value}" type="video/mp4"/>
            Seu navegador não suporta vídeos.
          </video>
        `)
      } else {
        setBackgroundContent(`
          <div style="width: 100%; height: 100%; background-image: url(${e.target.value}); background-size: cover; background-position: center;"></div>
        `)
      }
    } else {
      setSelectedBackground('')
    }
  }

  const updateSelectedItem = (updatedFields) => {
    setItems(items =>
      items.map((item, i) =>
        i === selectedItemIndex ? { ...item, ...updatedFields } : item,
      ),
    )
  }

  const convertItemsForBackend = (items) => {
    const area = document.getElementById('dragAndDropArea')
    if (!area) return items

    const areaRect = area.getBoundingClientRect()

    return items.map(item => {
      const itemWidthInPx = parseFloat(item.width)
      const itemHeightInPx = parseFloat(item.height)
      const itemLeftInPx = parseFloat(item.left)
      const itemTopInPx = parseFloat(item.top)

      const widthPercent = (itemWidthInPx / areaRect.width) * 100
      const heightPercent = (itemHeightInPx / areaRect.height) * 100
      const leftPercent = (itemLeftInPx / areaRect.width) * 100
      const topPercent = (itemTopInPx / areaRect.height) * 100

      return {
        ...item,
        width: widthPercent,
        height: heightPercent,
        left: leftPercent,
        top: topPercent,
      }
    })
  }

  function handleChangeModel(selectedModel) {
    setSelectedItemIndex(null)
    setItems(selectedModel.value)
  }


  const toggleFontStyle = (property, value) => {
    updateSelectedItem({
      [property]: items[selectedItemIndex][property] === value ? '' : value,
    });
  };

  const toggleTextAlign = (alignment) => {
    updateSelectedItem({ textAlign: items[selectedItemIndex].textAlign === alignment ? '' : alignment });
  };

  const getInitialLetterSpacing = () => {
    const currentSpacing = items[selectedItemIndex]?.letterSpacing;
    return currentSpacing ? parseInt(currentSpacing, 10) : 0;
  };

  const [letterSpacing, setLetterSpacing] = useState(getInitialLetterSpacing());

  const letterSpacingOptions = Array.from({ length: 401 }, (_, i) => i - 200);

  const handleLetterSpacingChange = (e) => {
    const newSpacing = e.target.value;
    setLetterSpacing(newSpacing);
    updateSelectedItem({ letterSpacing: `${newSpacing}px` });
  };

  const textTransformOptions = [
    { value: 'capitalize', icon: <IoText className='fs-5' /> },
    { value: 'uppercase', icon: <RxLetterCaseUppercase className='fs-5' /> },
    { value: 'lowercase', icon: <RxLetterCaseLowercase className='fs-5' /> },
  ];

  const handleTextTransformChange = (value) => {
    const currentTransform = items[selectedItemIndex].textTransform;
    const newTransform = currentTransform === value ? 'none' : value;
    updateSelectedItem({ textTransform: newTransform });
  };

  const fontFamilyOptions = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Courier New', label: 'Courier New' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Trebuchet MS', label: 'Trebuchet MS' },
    { value: 'Comic Sans MS', label: 'Comic Sans MS' },
    { value: 'Impact', label: 'Impact' },
    { value: 'Palatino Linotype', label: 'Palatino Linotype' },
    { value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' },
    { value: 'Tahoma', label: 'Tahoma' },
    { value: 'Gill Sans', label: 'Gill Sans' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Cambria', label: 'Cambria' },
    { value: 'Garamond', label: 'Garamond' },
    { value: 'Bookman', label: 'Bookman' },
    { value: 'Rockwell', label: 'Rockwell' },
    { value: 'Consolas', label: 'Consolas' },
    { value: 'Candara', label: 'Candara' },
    { value: 'Arial Black', label: 'Arial Black' },
    { value: 'Courier', label: 'Courier' },
    { value: 'Lucida Console', label: 'Lucida Console' },
    { value: 'Monaco', label: 'Monaco' },
  ];

  const handleFontFamilyChange = (fontFamily) => {
    updateSelectedItem({ fontFamily });
  };

  console.log(bibliotecaToAssociate);

  return (
    <>
      <div className='container'>
        <ToastContainer />
        <Row className='d-flex'>
          <div className='divSaveBtnClassWithFilter'>
            <div style={{ width: '20vw' }}>
              <Select
                placeholder='Modelos de template'
                id="modelOptions"
                onChange={(e) => handleChangeModel(e)}
                options={modelOptions}
              />
            </div>
            <div className='d-flex gap-2'>
              <Button
                onClick={handleSubmit}
                className="saveBtnClass mb-2"
                type='submit'
                disabled={isSubmitting}
              >
                <span className='d-flex align-items-center'>
                  {isSubmitting ? (
                    <div className="spinner-border spinner-border-sm me-1" role="status" />
                  ) : (
                    <MdSaveAlt className='fs-5 me-1' />
                  )}
                  Salvar
                </span>
              </Button>
            </div>
          </div>
          <div className='d-flex'>
            <Col>
              <div className='divMaquinasNovaPub shadowCustom h-100'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                  <span><TbLayoutGridAdd className='fs-5 me-1' />Adicione os itens ao template</span>
                </label>
                <div className="gridTemplateDefault p-3">
                  <Button className='btnTemplateDefault' onClick={() => addItem('video')}>
                    <AiOutlineVideoCameraAdd className='fs-4 me-1' />Adicionar Vídeo
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('foto')}>
                    <LuImagePlus className='fs-4 me-1' />Adicionar Foto
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('texto')}>
                    <MdOutlineTextIncrease className='fs-4 me-1' />Adicionar Texto
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('lista')}>
                    <MdFormatListNumbered className='fs-4 me-1' />Adicionar Lista
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('data')} disabled={itemExists('data')}>
                    <IoCalendarOutline className='fs-4 me-1' />Data
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('semana')} disabled={itemExists('semana')}>
                    <BiCalendarWeek className='fs-4 me-1' />Dia da Semana
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('relogio')} disabled={itemExists('relogio')}>
                    <LuClock className='fs-4 me-1' />Relógio
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('euro')} disabled={itemExists('euro')}>
                    <MdEuroSymbol className='fs-4 me-1' />Euro
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('dolar')} disabled={itemExists('dolar')}>
                    <BiDollar className='fs-4 me-1' />Dollar
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('min')} disabled={itemExists('min')}>
                    <FaTemperatureArrowDown className='fs-4 me-1' />Mínima ºC
                  </Button>
                  <Button className='btnTemplateDefault' onClick={() => addItem('max')} disabled={itemExists('max')}>
                    <FaTemperatureArrowUp className='fs-4 me-1' />Máxima ºC
                  </Button>
                </div>
              </div>
            </Col>
            <Col className='d-flex-column'>
              <Form.Group className='divMaquinasNovaPub shadowCustom'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                  <span><LuTextCursorInput className='fs-5 me-1' />Nome do Template</span>
                </label>
                <div className='p-2'>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Digite o nome do template'
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group className='divMaquinasNovaPub shadowCustom mt-2'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                  <span><PiSelectionBackgroundDuotone className='fs-5 me-1' />Plano de fundo</span>
                </label>
                <div className='p-2'>
                  <select onChange={handleChangeBackground} className="form-select">
                    <option value=''>Selecione...</option>
                    {backgrounds.map((bg, index) => (
                      <option key={index} value={`${doSpaces}${bg.caminho}/${bg.arquivo}`}>{bg.label}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>
              <Form.Group className='divMaquinasNovaPub shadowCustom mt-2'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                  <span><FaUsers className='fs-5 me-1' />Bibliotecas</span>
                </label>
                <div className='p-2'>
                  <Select
                    placeholder='Selecione a Biblioteca'
                    id="input-group-edit"
                    value={bibliotecaToAssociate}
                    onChange={(e) => handleChangeBiblioteca(e)}
                    options={bibliotecas}
                  />
                </div>
              </Form.Group>
            </Col>
          </div>
        </Row>
        <Row className="container mt-2" style={{ margin: '0px !important', padding: '0px !important' }}>
          {selectedItemIndex !== null && (
            <div className=' divMaquinasNovaPub shadowCustom'>
              <label className='d-flex justify-content-between fw-semibold borderDown p-2 bg-dark text-white text-nowrap'>
                <span>Atributos do item {items[selectedItemIndex].tipo}</span>
                <Button size='sm' className='deleteButton' onClick={removeSelectedItem} type='submit'>
                  <span className='d-flex align-items-center'><FaTrash className='fs-6 me-1' />Remover Item</span>
                </Button>
              </label>
              <Row className='rowGridFilterItensClass2'>
                <Col>
                  {items[selectedItemIndex].tipo !== 'relogio' &&
                    items[selectedItemIndex].tipo !== 'max' &&
                    items[selectedItemIndex].tipo !== 'data' &&
                    items[selectedItemIndex].tipo !== 'min' &&
                    items[selectedItemIndex].tipo !== 'dolar' &&
                    items[selectedItemIndex].tipo !== 'euro' && (
                      <div>
                        <Form.Group controlid='formBasicPassword' className='p-2'>
                          <label className='fw-semibold textColors text-nowrap'>
                            <span className='ms-1'>Nome {items[selectedItemIndex].tipo}:</span>
                          </label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Nome'
                            value={items[selectedItemIndex].nome}
                            onChange={(e) => updateSelectedItem({ nome: e.target.value })}
                          />
                        </Form.Group>
                      </div>
                    )}
                </Col>
              </Row>
              <Row className='rowGridFilterItensClass2 mt-1'>
                <Col xs={1} className='display-contents'>
                  {(items[selectedItemIndex].tipo === 'texto' ||
                    items[selectedItemIndex].tipo === 'data' ||
                    items[selectedItemIndex].tipo === 'semana' ||
                    items[selectedItemIndex].tipo === 'relogio' ||
                    items[selectedItemIndex].tipo === 'max' ||
                    items[selectedItemIndex].tipo === 'min' ||
                    items[selectedItemIndex].tipo === 'dolar' ||
                    items[selectedItemIndex].tipo === 'euro' ||
                    items[selectedItemIndex].tipo === 'lista') && (
                      <InputGroup className='ms-2' style={{ width: '80px' }} controlid='formBasicConfirmationPassword'>
                        {/* <InputGroup.Text><BiFontColor />{items[selectedItemIndex].tipo}</InputGroup.Text> */}
                        <InputGroup.Text>
                          <div className="tooltipDND">
                            <BiFontColor />
                            <span className="tooltipDNDtext">Cor do texto</span>
                          </div>
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='color'
                          placeholder='Selecione o tempo em segundos'
                          value={items[selectedItemIndex].fontColor}
                          onChange={(e) => updateSelectedItem({ fontColor: e.target.value })}
                        />

                      </InputGroup>
                    )}
                </Col>
                <Col xs={1} className='display-contents' style={{ position: 'relative' }}>
                  {(items[selectedItemIndex].tipo === 'texto' ||
                    items[selectedItemIndex].tipo === 'data' ||
                    items[selectedItemIndex].tipo === 'semana' ||
                    items[selectedItemIndex].tipo === 'relogio' ||
                    items[selectedItemIndex].tipo === 'max' ||
                    items[selectedItemIndex].tipo === 'min' ||
                    items[selectedItemIndex].tipo === 'dolar' ||
                    items[selectedItemIndex].tipo === 'euro' ||
                    items[selectedItemIndex].tipo === 'lista' ||
                    items[selectedItemIndex].tipo === 'foto' ||
                    items[selectedItemIndex].tipo === 'video') && (
                      <InputGroup className='ms-2' style={{ width: '80px' }}>
                        <InputGroup.Text>
                          <div className="tooltipDND">
                            <MdOutlineFormatColorFill />
                            <span className="tooltipDNDtext">Cor de fundo do texto</span>
                          </div>
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          readOnly
                          value={items[selectedItemIndex].backgroundColor}
                          onClick={toggleColorPicker}
                          onChange={(e) => updateSelectedItem({ backgroundColor: e.target.value })}
                          style={{ backgroundColor: color, color: color, border: '1px solid #ced4da', cursor: 'pointer' }}
                        />
                      </InputGroup>
                    )}
                  {showColorPicker && (
                    <div style={{ position: 'absolute', zIndex: 2, paddingLeft: '180px' }}>
                      <div
                        style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                        onClick={toggleColorPicker}
                        value={items[selectedItemIndex].backgroundColor}
                      />
                      <ChromePicker color={color} onChange={handleColorChange} />
                    </div>
                  )}
                </Col>
                <Col className='d-flex flex-column align-items-center display-contents'>
                  <Row className='gap-1'>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex].textAlign === 'left' ? 'primary' : 'secondary'}
                        onClick={() => toggleTextAlign('left')}
                        value={items[selectedItemIndex].textAlign}
                      >
                        <FaAlignLeft />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex].textAlign === 'center' ? 'primary' : 'secondary'}
                        onClick={() => toggleTextAlign('center')}
                      >
                        <FaAlignCenter />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex].textAlign === 'right' ? 'primary' : 'secondary'}
                        onClick={() => toggleTextAlign('right')}
                      >
                        <FaAlignRight />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex].textAlign === 'justify' ? 'primary' : 'secondary'}
                        onClick={() => toggleTextAlign('justify')}
                      >
                        <FaAlignJustify />
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col className='d-flex flex-column align-items-center display-contents'>
                  <Row className='gap-1'>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex]?.fontWeight === 'bold' ? 'primary' : 'secondary'}
                        onClick={() => toggleFontStyle('fontWeight', 'bold')}
                      >
                        <FaBold />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex]?.fontWeight === 'normal' ? 'primary' : 'secondary'}
                        onClick={() => toggleFontStyle('fontWeight', 'normal')}
                      >
                        <FaFont />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex]?.fontStyle === 'italic' ? 'primary' : 'secondary'}
                        onClick={() => toggleFontStyle('fontStyle', 'italic')}
                      >
                        <FaItalic />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex]?.textDecoration === 'underline' ? 'primary' : 'secondary'}
                        onClick={() => toggleFontStyle('textDecoration', 'underline')}
                      >
                        <FaUnderline />
                      </Button>
                    </Col>
                    <Col className='display-contents'>
                      <Button
                        variant={items[selectedItemIndex]?.textDecoration === 'line-through' ? 'primary' : 'secondary'}
                        onClick={() => toggleFontStyle('textDecoration', 'line-through')}
                      >
                        <FaStrikethrough />
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col className='d-flex flex-column align-items-center display-contents'>
                  <Form.Group controlId='textTransform'>
                    <ButtonGroup>
                      {textTransformOptions.map(option => (
                        <Button
                          key={option.value}
                          variant={items[selectedItemIndex].textTransform === option.value ? 'primary' : 'secondary'}
                          onClick={() => handleTextTransformChange(option.value)}
                        >
                          {option.icon} {option.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Form.Group>
                </Col>
                <Col xs={2} className='display-contents'>
                  <InputGroup controlId='letterSpacing' style={{ width: '140px' }} className='me-2'>
                    <InputGroup.Text>
                      <div className="tooltipDND">
                        <RxLetterSpacing />
                        <span className="tooltipDNDtext">Espaçamento entre letras</span>
                      </div>
                    </InputGroup.Text>
                    <Form.Select
                      value={letterSpacing}
                      onChange={handleLetterSpacingChange}
                    >
                      {letterSpacingOptions.map(value => (
                        <option key={value} value={value}>{value}px</option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs={2} className='display-contents'>
                  <InputGroup style={{ width: '150px' }} className='me-2'>
                    <InputGroup.Text>
                      <div className="tooltipDND">
                        <RxFontFamily />
                        <span className="tooltipDNDtext">Fonte do texto</span>
                      </div>
                    </InputGroup.Text>
                    <select onChange={(e) => handleFontFamilyChange(e.target.value)} className="form-select">
                      {fontFamilyOptions.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </InputGroup>
                </Col>
              </Row>
              <Row className='rowGridFilterItensClass2'>
                <Col className='p-0 display-contents'>
                  {(items[selectedItemIndex].tipo === 'texto' ||
                    items[selectedItemIndex].tipo === 'relogio' ||
                    items[selectedItemIndex].tipo === 'data' ||
                    items[selectedItemIndex].tipo === 'semana' ||
                    items[selectedItemIndex].tipo === 'max' ||
                    items[selectedItemIndex].tipo === 'min' ||
                    items[selectedItemIndex].tipo === 'dolar' ||
                    items[selectedItemIndex].tipo === 'euro' ||
                    items[selectedItemIndex].tipo === 'lista') && (
                      <div className='p-2'>
                        <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Tamanho fonte:</span>
                        </label>
                        <InputGroup style={{ width: '170px' }} controlId='formBasicConfirmationPassword'>
                          <InputGroup.Text>
                            <AiOutlineFontSize />
                          </InputGroup.Text>
                          <Form.Control
                            min={1}
                            required
                            type='number'
                            placeholder='Tamanho da fonte'
                            value={items[selectedItemIndex].fontSize}
                            onChange={(e) => updateSelectedItem({ fontSize: e.target.value })}
                          />
                          <InputGroup.Text>pt</InputGroup.Text>
                        </InputGroup>
                      </div>
                    )}
                </Col>
                <Col xs='auto'>
                  <div className='p-2'>
                    <label className='fw-semibold textColors text-nowrap'>
                      <span className='ms-1'>Tempo para {items[selectedItemIndex].tipo} aparecer:</span>
                    </label>
                    <InputGroup style={{ width: 'auto' }} controlid='formBasicConfirmationPassword'>
                      <InputGroup.Text><CgSandClock /></InputGroup.Text>
                      <Form.Control
                        required
                        type='number'
                        placeholder='Selecione o tempo em segundos (Opcional)'
                        value={items[selectedItemIndex].fadeIn}
                        onChange={(e) => updateSelectedItem({ fadeIn: e.target.value })}
                      />
                      <InputGroup.Text>s</InputGroup.Text>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={3}>
                  <div className='p-2'>
                    <label className='fw-semibold textColors text-nowrap'>
                      <span className='ms-1'>Tempo para {items[selectedItemIndex].tipo} desaparecer:</span>
                    </label>
                    <InputGroup style={{ width: 'auto' }} controlid='formBasicConfirmationPassword'>
                      <InputGroup.Text><CgSandClock /></InputGroup.Text>
                      <Form.Control
                        required
                        type='number'
                        placeholder='Selecione o tempo em segundos'
                        value={items[selectedItemIndex].fadeOut}
                        onChange={(e) => updateSelectedItem({ fadeOut: e.target.value })}
                      />
                      <InputGroup.Text>s</InputGroup.Text>
                    </InputGroup>
                  </div>
                </Col>

                {items[selectedItemIndex].tipo === 'lista' && (
                  <Col>
                    <div>
                      <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                        <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Itens da {items[selectedItemIndex].tipo}:</span>
                        </label>
                        <Form.Control
                          min={1}
                          required
                          type='text'
                          placeholder="Separador ';'"
                          value={items[selectedItemIndex].lista}
                          onChange={(e) => updateSelectedItem({ lista: e.target.value })}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          )}
          <div
            id="dragAndDropArea"
            className="container mt-2 mb-lg-5"
            style={{
              width: '1920px',
              height: '1080px',
              margin: '0px',
              padding: '0px',
              position: 'relative',
              border: '1px solid #ccc',
              overflow: 'hidden',
            }}
          >
            <div
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              dangerouslySetInnerHTML={{ __html: backgroundContent }}
            ></div>
            {items.map((item, index) => {
              let content = item.nome;

              if (item.tipo === 'foto') {
                content = '<img src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg" alt="Imagem de exemplo" style="width: 100%; height: 100%; object-fit: fill; position: absolute;" />';
              } else if (item.tipo === 'video') {
                content = `
      <video
        src="https://storageliveatmo.nyc3.digitaloceanspaces.com/videoteca/2021-08-23_14-18-59_6123ae53b5188.mp4"
        style="width: 100%; height: 100%; object-fit: contain; pointer-events: none; border: 1px solid #ccc;"
        muted
        autoplay
        loop
      ></video>`;
              } else if (item.tipo === 'texto') {
                content = `
      <div style="
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: ${Math.floor(item.height / item.fontSize)};
        -webkit-box-orient: vertical;
        text-decoration: ${item.textDecoration};
      ">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque volutpat, dui sit amet facilisis fermentum.
      </div>`;
              }

              const defaultTop = 0;
              const defaultLeft = 0;

              return (
                <div
                  key={index}
                  className={`resize-drag ${selectedItemIndex === index && item.tipo !== 'video' ? 'borderdndArea' : ''}`}
                  data-index={index}
                  style={{
                    width: `${item.width}px`,
                    height: `${item.height}px`,
                    transform: `translate(${item.left || defaultLeft}px, ${item.top || defaultTop}px)`,
                    position: 'absolute',
                    border: 'none',
                    backgroundColor: item.backgroundColor,
                    color: item.fontColor,
                    fontSize: `${item.fontSize}px`,
                    textAlign: item.textAlign,
                    fontWeight: item.fontWeight,
                    fontStyle: item.fontStyle,
                    textDecoration: item.textDecoration,
                    textTransform: item.textTransform,
                    letterSpacing: item.letterSpacing,
                    textShadow: item.textShadow,
                    fontVariant: item.fontVariant,
                    fontFamily: item.fontFamily,
                    animation: `fadeInOut ${item.fadeIn || 0}s ${item.fadeOut || 0}s`,
                  }}
                  onClick={() => handleItemClick(index)}
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                  {selectedItemIndex === index && item.tipo !== 'video' && (
                    <>
                      <div className="handle nw"></div>
                      <div className="handle ne"></div>
                      <div className="handle sw"></div>
                      <div className="handle se"></div>
                      <div className="handle n"></div>
                      <div className="handle s"></div>
                      <div className="handle w"></div>
                      <div className="handle e"></div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-13px',
                          right: '0px',
                          cursor: 'pointer',
                          color: '#ff0000',
                          padding: '5px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSelectedItem();
                        }}
                      ><FaRegTrashAlt className='fs-5' />
                      </div>
                    </>
                  )}
                </div>
              );
            })}

          </div>
        </Row>
      </div>
    </>
  )
}

export default DragAndDropArea