import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationFornecedorLottie from './AnimationFornecedorLottie/AnimationFornecedorLottie'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [fornecedores, setFornecedores] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [fornecedorIdToDelete, setFornecedorIdToDelete] = useState('')
    const [fornecedorNameToDelete, setFornecedorNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [fornecedoresPerPage] = useState(10)
    const indexOfLastFornecedor = currentPage * fornecedoresPerPage
    const indexOfFirstFornecedor = indexOfLastFornecedor - fornecedoresPerPage
    const currentFornecedores = fornecedores.slice(indexOfFirstFornecedor, indexOfLastFornecedor)
    const nPages = Math.ceil(fornecedores.length / fornecedoresPerPage)
    const [loading, setLoading] = useState(true);

    const getFornecedores = async () => {

        setLoading(true);

        try {
            const [userPermissionsResponse, fornecedoresResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/fornecedor/index')
            ]);

            setUserPermissions(userPermissionsResponse.data.permissions);
            setFornecedores(fornecedoresResponse.data.fornecedores);
        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/fornecedor/index')
        setFornecedores(response.data.fornecedores)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (fornecedorId, fornecedorName) => {
        setModalShow(true)
        setFornecedorIdToDelete(fornecedorId)
        setFornecedorNameToDelete(fornecedorName)
    }

    const deleteFornecedor = async (fornecedorId) => {
        await api.delete(`/api/fornecedor/${fornecedorId}`).then(() => {
            getFornecedores()
            setModalShow(false)
            toast.success('Fornecedor deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteFornecedorConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Fornecedor.</h4>
                    <p>
                        Você tem certeza de que deseja remover este fornecedor <span className='d-flex justify-content-center mb-0'>{props.fornecedorname} ?</span>
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteFornecedor(props.fornecedorid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const nextPage = () => {
        if (currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }

    const prevPage = () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }

    const showEditFornecedorPage = (fornecedorId) => {
        history(`/fornecedor/edit/${fornecedorId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        return (
            <nav className='d-flex justify-content-center align-items-center'>

                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={prevPage} />

                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {
                        pageNumbers.map(pageNumber => (
                            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''} `}>
                                <button onClick={() => setCurrentPage(pageNumber)} className='page-link'>
                                    {pageNumber}
                                </button>
                            </li>
                        ))
                    }
                </ul>

                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={nextPage} />

            </nav>
        )
    }

    useEffect(() => {
        getFornecedores()
    }, [])

    const filteredFornecedores = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentFornecedores
        } else {
            return fornecedores.filter((fornecedor) => fornecedor.nome.toLowerCase().includes(lowerSearch) || fornecedor.id.toString().toLowerCase().includes(lowerSearch) || fornecedor.contato?.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, fornecedores, currentFornecedores])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
            <div id='divContainer' className='container'>
                <DeleteFornecedorConfirmaitonModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    fornecedorid={fornecedorIdToDelete}
                    fornecedorname={fornecedorNameToDelete}
                />

                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        {/* <div id='divHeaderTittle'><AnimationFornecedorLottie /><h3 id='tittleH2'>Fornecedores</h3></div> */}
                        <div id='divHeaderTittle'><AnimationFornecedorLottie/><h3 id='tittleH2'>Fornecedores</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {
                            userPermissions.some((permission) => permission.name === 'store_fornecedor') ? (
                                <NavLink className='btnVoltarMobile' to='/fornecedor/new' style={{ textDecoration: 'none' }}>
                                    <Button id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='downloadDashButtonMobile'>Novo Fornecedor</span></Button>
                                </NavLink>
                            ) : ''
                        }
                    </div>
                </div>

                <ToastContainer />

                <div className='container overflow-auto mt-2'>
                    <Table id='tableList' striped bordered hover>
                        <thead>
                            <tr id="trList">
                                <th className='th-text-center'>#ID</th>
                                <th className='th-text-center'>Nome</th>
                                <th className='th-text-center'>Contato</th>
                                <th id='thAction'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredFornecedores.map((fornecedor, index) => (
                                <tr key={fornecedor.id}>
                                    <td className='td-text-center fontCustom'>{fornecedor.id}</td>
                                    <td className='td-text-center fontCustom'>{fornecedor.nome}</td>
                                    <td className='td-text-center fontCustom'>{fornecedor.contato}</td>
                                    <td id='tdButtonAction'>
                                        {
                                            userPermissions.some((permission) => permission.name === 'edit_fornecedor') ? (
                                                <Button className='editButton btnTableMoBile' onClick={() => showEditFornecedorPage(fornecedor.id)}><BsPencilSquare /> Editar</Button>
                                            ) : ''
                                        }

                                        {
                                            userPermissions.some((permission) => permission.name === 'delete_fornecedor') ? (
                                                <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(fornecedor.id, fornecedor.name)}><FaTrash /> Excluir</Button>
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />

            </div>
            )}
        </>
    )
}

export default Index