import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login/login.js'
import LoginQrCode from './pages/Login/login_qrcode.js'
import LoginLojista from './pages/LoginLojista/loginlojista.js'
import PasswordResetLink from './pages/Login/forgotPassword.js'
import FormPasswordReset from './pages/Login/formResetPassword.js'
import Dashboard from './pages/Dashboard/dashboard.js'
import DashboardPublicacao from './pages/DashboardPublicacao/dashboardPublicacao.js'
import RelatorioDashboardPublicacao from './pages/DashboardPublicacao/relatorioPublicacao.js'
import RelatorioMaquinas from './pages/Dashboard/RelatorioMaquinas.js'
import Users from './pages/Users/index.js'
import CreateUser from './pages/Users/create.js'
import EditUser from './pages/Users/Edit.js'
import Roles from './pages/Roles/index.js'
import CreateRole from './pages/Roles/create.js'
import Permissions from './pages/Permissions/index.js'
import CreatePermission from './pages/Permissions/create.js'
import EditRole from './pages/Roles/edit.js'
import Ambientes from './pages/Ambientes/index.js'
import CreateAmbiente from './pages/Ambientes/create.js'
import EditAmbiente from './pages/Ambientes/edit.js'
import Clients from './pages/Clients/index.js'
import CreateClient from './pages/Clients/create.js'
import EditClient from './pages/Clients/edit.js'
import Grades from './pages/Grades/index.js'
import CreateGrade from './pages/Grades/create.js'
import EditGrade from './pages/Grades/edit.js'
import Locais from './pages/Locais/index.js'
import CreateLocal from './pages/Locais/create.js'
import EditLocal from './pages/Locais/edit.js'
import Grupos from './pages/Grupos/index.js'
import CreateGrupo from './pages/Grupos/create.js'
import EditGrupo from './pages/Grupos/edit.js'
import Conteudo from './pages/Conteudo/index.js'
import CreateConteudo from './pages/Conteudo/create.js'
import EditConteudo from './pages/Conteudo/edit.js'
import Maquinas from './pages/Maquinas/index.js'
import CreateMaquina from './pages/Maquinas/create.js'
import EditMaquina from './pages/Maquinas/edit.js'
import Bibliotecas from './pages/Bibliotecas/index.js'
import CreateBibliotecas from './pages/Bibliotecas/create.js'
import EditBibliotecas from './pages/Bibliotecas/edit.js'
import ShowBibliotecas from './pages/Bibliotecas/show.js'
import CreateTemplate from './pages/Templates/create.js'
import EditTemplate from './pages/Templates/edit.js'
import Templates from './pages/Templates/index.js'
import CreatePublication from './pages/Publicacao/create.js'
import EditPublication from './pages/Publicacao/edit.js'
import Publications from './pages/Publicacao/index.js'
import CreateFornecedor from './pages/Fornecedor/create.js'
import EditFornecedor from './pages/Fornecedor/edit.js'
import Fornecedores from './pages/Fornecedor/index.js'
import CreateModeloMaquina from './pages/ModeloMaquina/create.js'
import EditModeloMaquina from './pages/ModeloMaquina/edit.js'
import ModelosMaquina from './pages/ModeloMaquina/index.js'
import PublicacaoLojista from './pages/PublicacaoLojista/index.js'
import ValidationLojistaPublicacaoLojista from './pages/PublicacaoLojista/validationLojista.js'
import CreatePublicacaoLojista from './pages/PublicacaoLojista/create.js'
import EditPublicacaoLojista from './pages/PublicacaoLojista/edit.js'
import Editorias from './pages/Editorias/index.js'
import CreateEditoria from './pages/Editorias/create.js'
import EditEditoria from './pages/Editorias/edit.js'
import Provedores from './pages/Provedores/index.js'
import CreateProvedor from './pages/Provedores/create.js'
import EditProvedor from './pages/Provedores/edit.js'
import Noticias from './pages/Noticias/index.js'
import CreateNoticia from './pages/Noticias/create.js'
import EditNoticia from './pages/Noticias/edit.js'
import ApproveNoticia from './pages/Noticias/approvals.js'
import EditMaterial from './pages/Bibliotecas/editMaterial.js'
import CreateMaterial from './pages/Bibliotecas/createMaterial.js'
import Logs from './pages/Logs/index.js'
import Comandos from './pages/Comandos/index.js'
import CreateComando from './pages/Comandos/create.js'
import EditComando from './pages/Comandos/edit.js'
import ComandosMaquina from './pages/ComandosMaquina/index.js'
import CreateComandoMaquina from './pages/ComandosMaquina/create.js'
import EditComandoMaquina from './pages/ComandosMaquina/edit.js'
import Aprovacao from './pages/Aprovacao/index.js'
import UploadExcel from './pages/UploadExcel/index.js'
import CreateTemplatePadrao from './pages/TemplatePadrao/create.js'
import EditTemplatePadrao from './pages/TemplatePadrao/edit.js'
import TemplatePadrao from './pages/TemplatePadrao/index.js'
import Screenshots from './pages/Maquinas/screenshots.js'

export default function AppRoutes() {
  
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/login_qrcode/:hash_qrcode' element={<LoginQrCode />} />
      <Route path='/login/:routeToken' element={<LoginLojista />} />
      <Route path='/passwordResetLink' element={<PasswordResetLink />} />
      <Route path='/formPasswordReset' element={<FormPasswordReset />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/dashboard/maquinasRelatorio' element={<RelatorioMaquinas />} />

      <Route path='/dashboardPublicacao' element={<DashboardPublicacao />} />
      <Route path='/dashboardPublicacao/relatorioPublicacao' element={<RelatorioDashboardPublicacao />} />
      <Route path='/uploadExcel' element={<UploadExcel />} />
      
      <Route path='/template/padrao/create' element={<CreateTemplatePadrao />} />
      <Route path='/template/padrao/edit/:id' element={<EditTemplatePadrao />} />
      <Route path='/template/padrao/index' element={<TemplatePadrao />} />

      <Route path='/publicacoes' element={<Publications />} />
      <Route path='/publicacao/new' element={<CreatePublication />} />
      <Route path='/publicacao/edit/:id' element={<EditPublication />} />
      
      <Route path='/fornecedores' element={<Fornecedores />} />
      <Route path='/fornecedor/new' element={<CreateFornecedor />} />
      <Route path='/fornecedor/edit/:id' element={<EditFornecedor />} />

      <Route path='/modelosMaquina' element={<ModelosMaquina />} />
      <Route path='/modeloMaquina/new' element={<CreateModeloMaquina />} />
      <Route path='/modeloMaquina/edit/:id' element={<EditModeloMaquina />} />

      <Route path='/publicacaoLojista/validationLojista/:id' element={<ValidationLojistaPublicacaoLojista />} />
      <Route path='/publicacaoLojista' element={<PublicacaoLojista />} />
      <Route path='/publicacaoLojista/new' element={<CreatePublicacaoLojista />} />
      <Route path='/publicacaoLojista/edit/:id' element={<EditPublicacaoLojista />} />

      <Route path='/users' element={<Users />} />
      <Route path='/user/new' element={<CreateUser />} />
      <Route path='/user/edit/:id' element={<EditUser />} />

      <Route path='/roles' element={<Roles />} />
      <Route path='/role/new' element={<CreateRole />} />
      <Route path='/role/edit/:id' element={<EditRole />} />
      
      <Route path='/permissions' element={<Permissions />} />
      <Route path='/permission/new' element={<CreatePermission />} />

      <Route path='/ambientes' element={<Ambientes />} />
      <Route path='/ambiente/new' element={<CreateAmbiente />} />
      <Route path='/ambiente/edit/:id' element={<EditAmbiente />} />

      <Route path='/clientes' element={<Clients />} />
      <Route path='/cliente/new' element={<CreateClient />} />
      <Route path='/cliente/edit/:id' element={<EditClient />} />

      <Route path='/grades' element={<Grades />} />
      <Route path='/grade/new' element={<CreateGrade />} />
      <Route path='/grade/edit/:id' element={<EditGrade />} />

      <Route path='/locais' element={<Locais />} />
      <Route path='/local/new' element={<CreateLocal />} />
      <Route path='/local/edit/:id' element={<EditLocal />} />

      <Route path='/grupos' element={<Grupos />} />
      <Route path='/grupo/new' element={<CreateGrupo />} />
      <Route path='/grupo/edit/:id' element={<EditGrupo />} />

      <Route path='/aprovacao' element={<Aprovacao />} />

      <Route path='/conteudo' element={<Conteudo />} />
      <Route path='/conteudo/new' element={<CreateConteudo />} />
      <Route path='/conteudo/edit/:id' element={<EditConteudo />} />

      <Route path='/maquinas' element={<Maquinas />} />
      <Route path='/maquinas/new' element={<CreateMaquina />} />
      <Route path='/maquinas/edit/:id' element={<EditMaquina />} />
      <Route path='/maquinas/:idLocal' element={<Maquinas />} />
      <Route path='/maquinas/new/:idLocal' element={<CreateMaquina />} />
      <Route path='/maquinas/edit/:id' element={<EditMaquina />} />
      <Route path='/maquinas/screenshots' element={<Screenshots />} />

      <Route path='/bibliotecas' element={<Bibliotecas />} />
      <Route path='/bibliotecas/new' element={<CreateBibliotecas />} />
      <Route path='/bibliotecas/edit/:id' element={<EditBibliotecas />} />
      <Route path='/bibliotecas/show/:id' element={<ShowBibliotecas />} />

      <Route path='/templates' element={<Templates />} />
      <Route path='/template/new' element={<CreateTemplate />} />
      <Route path='/template/edit/:id' element={<EditTemplate />} />

      <Route path='/editorias' element={<Editorias />} />
      <Route path='/editoria/new' element={<CreateEditoria />} />
      <Route path='/editoria/edit/:id' element={<EditEditoria />} />

      <Route path='/provedores' element={<Provedores />} />
      <Route path='/provedor/new' element={<CreateProvedor />} />
      <Route path='/provedor/edit/:id' element={<EditProvedor />} />

      <Route path='/noticias' element={<Noticias />} />
      <Route path='/noticia/new' element={<CreateNoticia />} />
      <Route path='/noticia/edit/:id' element={<EditNoticia />} />
      <Route path='/approvals' element={<ApproveNoticia />} />

      <Route path='/material/:id/:idMaterial' element={<EditMaterial />} />
      <Route path='/materialCreate/:id' element={<CreateMaterial />} />

      <Route path='/logs' element={<Logs />} />

      <Route path='/comandos' element={<Comandos />} />
      <Route path='/comando/new' element={<CreateComando />} />
      <Route path='/comando/edit/:id' element={<EditComando />} />

      <Route path='/comandosMaquina' element={<ComandosMaquina />} />
      <Route path='/comandoMaquina/new' element={<CreateComandoMaquina />} />
      <Route path='/comandoMaquina/edit/:id' element={<EditComandoMaquina />} />

    </Routes>
  )
}
