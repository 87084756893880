import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../services/api.js'
import { Button, Form, Label, Input, Container, Row, Col, Alert } from 'reactstrap'
import Cookies from 'js-cookie'
import atmoLogo from './atmo publicador (2).jpg'
import atmoLogo2 from './logo_atmo_publicador.png'
import BackgroundLoginPage from "./backgroundLoginPage/backgroundLoginPage.js"
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton.js";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify'

import './style.css'
import '../../components/MobileModeStyle/loginMobileStyle.css'
import { InputGroup } from 'react-bootstrap'

function Login() {
    const [hash, setHash] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { hash_qrcode } = useParams()
    const customId = 'success-toast-id'


console.log(process.env.REACT_APP_API_PUBLICADOR_URL)

const handleLogin = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    setError(null); // Limpar erros anteriores

    try {
         await api.post(`${process.env.REACT_APP_API_PUBLICADOR_URL}/api/publicador/auth/qrcode/${hash_qrcode}`, {
            hash, // Envia o hash inserido pelo usuário
        }).then(response => {
            toast.success('Login realizado com sucesso!', { toastId: customId, autoClose: 3000, onClose: setHash('') })
          });

    } catch (error) {
        toast.error('Hash incorreto!', { toastId: customId, autoClose: 3000 })
    } finally {
        setShowSpinner(false);
    }
};

    
    const handleClearError = () => {
        setError(null);
    };


    return (
        <>
      <ToastContainer />
            <div className='backgroundColorLogin'>
                <div className='div-whatsapp-button'>
                    <WhatsAppButton />
                </div>

                <Container id="containerLogin" className='containerLoginMobile'>
                    <img id="imgAtmoLogoLoginADM" className='imgAtmoLogoLoginMobile' src={atmoLogo} alt='Logo Atmo' />
                    <Row id="rowContainerLogin" className='rowContainerLoginMobile'>
                        <Col sm={9} className='colFormLoginWH colFormLoginMobile'>
                            <div className='d-flex justify-content-center logoAtmoMobile p-3'>
                                <img className='w-75' src={atmoLogo2} alt='Logo Atmo' />
                            </div>
                            <Form onSubmit={handleLogin} className='formGapRowClass'>
                                <Label id='textLogin' className='textLoginMobile letterSpacingClass text-dark'>Login</Label>
                                <div>
                                    {/* <Label for="email"></Label> */}
                                    <label className='d-flex align-items-center colorTextInputLogin'>
                                        <MdOutlineAlternateEmail className='me-1' />
                                        <span className='fw-semibold spanTextLoginADM'>
                                            HASH
                                        </span>
                                    </label>
                                    <Input
                                        placeholder='Insira o hash aqui.'
                                        className='inputBorderADMClass'
                                        type="text"
                                        value={hash}
                                        onChange={(e) => setHash(e.target.value)}
                                        onClick={handleClearError} // Limpar mensagem de erro ao clicar no campo de email
                                        required
                                    />
                                </div>

                                <div className='d-flex flex-column w-100 pb-3'>
                                    <Button className='w-100' id='btnEntrarLogin' type="submit" disabled={showSpinner}>
                                        {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Entrar</div>}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <BackgroundLoginPage />
            </div>
        </>
    )
}

export default Login
