import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { FaUserLock } from 'react-icons/fa'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [permissions, setPermissions] = useState([])
    const [permissionsToAssociate, setPermissionsToAssociate] = useState([])
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editRole = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 })
        } else {
            await api.put(`/api/role/${id}`, {
                name,
                permissionsToAssociate
            }).then(() => {
                setName('')
                toast.success('Perfil editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/roles') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/role/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.role.name)
            })

            await api.get(`api/permission/index`).then(res => {
                if (cancel) return

                let permissionsData = []

                res.data.permissions.forEach(permission => {
                    permissionsData.push({
                        value: permission.id,
                        label: permission.name
                    })
                })

                setPermissions(permissionsData)
            })

            await api.get(`api/permissions/role/${id}`).then(res => {
                if (cancel) return

                let permissionsData = []

                res.data.permissionsArray.forEach(permission => {
                    permissionsData.push({
                        value: permission.id,
                        label: permission.name
                    })
                })

                setPermissionsToAssociate(permissionsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><FaUserLock className='fs-1' /><h3 id='tittleH2'> Edição de perfil</h3></div>
                    </div>
                    <div className='divbtnVoltarMobile'>
                        <NavLink to='/roles' style={{ textDecoration: 'none' }}>
                            <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </NavLink>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='formGenMobile'>
                        <Form.Group className='mb-3' controlid='formBasicName'>
                            <Form.Label className='fw-semibold'>Nome</Form.Label>
                            <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />

                            <Form.Label className='fw-semibold mt-3'>Permissões</Form.Label>
                            <Select className='input-group-edit' value={permissionsToAssociate} onChange={(e) => setPermissionsToAssociate(e)} options={permissions} isMulti={true} />
                        </Form.Group>
                        <Row className='mb-4'>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editRole} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit