import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import api from '../../services/api'
import { Button, Form, Label, Input, Container, Row, Col, Alert } from 'reactstrap'
import Cookies from 'js-cookie'
import atmoLogo from './atmo publicador (2).jpg'
import atmoLogo2 from './logo_atmo_publicador.png'
import BackgroundLoginPage from "./backgroundLoginPage/backgroundLoginPage.js"
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton.js";
import { BiShow, BiHide } from "react-icons/bi";
import { TbLockX } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";

import './style.css'
import '../../components/MobileModeStyle/loginMobileStyle.css'
import { InputGroup } from 'react-bootstrap'

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const [mostrarSenha, setMostrarSenha] = useState(false);


    const handleLogin = async (e) => {
        e.preventDefault()
        setShowSpinner(true);

        try {
            const response = await api.post('/api/login', {
                email,
                password,
            })

            const token = response.data.token

            Cookies.set("token", token, { expires: 7, secure: false })
            Cookies.set("userId", response.data.userId, { expires: 7, secure: false })
            Cookies.set("userName", response.data.userName, { expires: 7, secure: false })
            Cookies.set("ambienteId", response.data.ambienteId, { expires: 7, secure: false })
            Cookies.set("ambienteLogoUrl", response.data.ambienteLogoUrl, { expires: 7, secure: false })
            navigate("/dashboard")

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("E-mail ou senha incorretos."); // Define a mensagem de erro para credenciais inválidas
            } else {
                setError("Erro ao fazer login."); // Outros erros
            }
            console.error('Erro ao fazer login:', error)
        } finally {
            setShowSpinner(false);
        }
    }
    const handleToggleMostrarSenha = () => {
        setMostrarSenha(!mostrarSenha);
    };
    const handleClearError = () => {
        setError(null);
    };

    const handleForgotPassword = () => {
        navigate("/passwordResetLink");
    };

    return (
        <>
            <div className='backgroundColorLogin'>
                <div className='div-whatsapp-button'>
                    <WhatsAppButton />
                </div>

                <Container id="containerLogin" className='containerLoginMobile'>
                    <img id="imgAtmoLogoLoginADM" className='imgAtmoLogoLoginMobile' src={atmoLogo} alt='Logo Atmo' />
                    <Row id="rowContainerLogin" className='rowContainerLoginMobile'>
                        <Col sm={9} className='colFormLoginWH colFormLoginMobile'>
                            <div className='d-flex justify-content-center logoAtmoMobile'>
                                <img className='w-75' src={atmoLogo} alt='Logo Atmo' />
                            </div>
                            <Form onSubmit={handleLogin} className='formGapRowClass'>
                                <Label id='textLogin' className='textLoginMobile letterSpacingClass text-dark'>Login</Label>
                                <div>
                                    {/* <Label for="email"></Label> */}
                                    <label className='d-flex align-items-center colorTextInputLogin'>
                                        <MdOutlineAlternateEmail className='me-1' />
                                        <span className='fw-semibold spanTextLoginADM'>
                                            Email
                                        </span>
                                    </label>
                                    <Input
                                        className='inputBorderADMClass'
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onClick={handleClearError} // Limpar mensagem de erro ao clicar no campo de email
                                        required
                                    />
                                </div>
                                <div className='divMarginTopClass'>
                                    {/* <Label for="password"></Label> */}
                                    <label className='d-flex align-items-center colorTextInputLogin z-3'>
                                        <RiLockPasswordLine className='me-1' />
                                        <span className='fw-semibold spanTextLoginADM'>
                                            Senha
                                        </span>
                                    </label>
                                    <InputGroup className='inputGroupPwdLogin z-1'>
                                        <Input
                                            type={mostrarSenha ? 'text' : 'password'}
                                            className='inputBorder2ADMClass'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onClick={handleClearError} // Limpar mensagem de erro ao clicar no campo de senha
                                            required
                                        // aria-label="Recipient's username"
                                        // aria-describedby="basic-addon2"
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" type="button" onClick={handleToggleMostrarSenha}>
                                            {mostrarSenha ? <BiShow className='fs-5' /> : <BiHide className='fs-5' />}
                                        </Button>
                                    </InputGroup>
                                    {error && <Alert className='alertClass d-flex align-items-center' color="danger"><TbLockX className='fs-5 marginAlertClass' />{error}</Alert>}
                                </div>

                                <div className='d-flex flex-column w-100'>
                                    <Button className='w-100' id='btnEntrarLogin' type="submit" disabled={showSpinner}>
                                        {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Entrar</div>}
                                    </Button>
                                    <Label className='text-dark' id='forgotPassword' onClick={handleForgotPassword}>Esqueceu a senha ?</Label>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <BackgroundLoginPage />
            </div>
        </>
    )
}

export default Login
