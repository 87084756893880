import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationModeloMaquinasLottie from './AnimationModeloMaquinasLottie/AnimationModeloMaquinasLottie'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [modelosMaquina, setModelosMaquina] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modeloMaquinaIdToDelete, setModeloMaquinaIdToDelete] = useState('')
    const [modeloMaquinaNameToDelete, setModeloMaquinaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [modelosMaquinaPerPage] = useState(10)
    const indexOfLastModeloMaquina = currentPage * modelosMaquinaPerPage
    const indexOfFirstModeloMaquina = indexOfLastModeloMaquina - modelosMaquinaPerPage
    const currentModelosMaquina = modelosMaquina.slice(indexOfFirstModeloMaquina, indexOfLastModeloMaquina)
    const nPages = Math.ceil(modelosMaquina.length / modelosMaquinaPerPage)
    const [loading, setLoading] = useState(true);

    const getModelosMaquina = async () => {

        setLoading(true);

        try {
            const [userPermissionsResponse, modelosResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/modeloMaquina/index')
            ]);

            setUserPermissions(userPermissionsResponse.data.permissions);
            setModelosMaquina(modelosResponse.data.modelos);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/modeloMaquina/index')
        setModelosMaquina(response.data.modelos)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (modeloMaquinaId, modeloMaquinaName) => {
        setModalShow(true)
        setModeloMaquinaIdToDelete(modeloMaquinaId)
        setModeloMaquinaNameToDelete(modeloMaquinaName)
    }

    const deleteModeloMaquina = async (modeloMaquinaId) => {
        await api.delete(`/api/modeloMaquina/${modeloMaquinaId}`).then(() => {
            getModelosMaquina()
            setModalShow(false)
            toast.success('ModeloMaquina deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteModeloMaquinaConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Modelo Maquina.</h4>
                    <p>
                        Você tem certeza de que deseja remover este Modelo Maquina <span className='d-flex justify-content-center mb-0'>{props.modeloMaquinaname} ?</span>
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteModeloMaquina(props.modeloMaquinaid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const nextPage = () => {
        if (currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }

    const prevPage = () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }

    const showEditModeloMaquinaPage = (modeloMaquinaId) => {
        history(`/modeloMaquina/edit/${modeloMaquinaId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

        return (
            <nav className='d-flex justify-content-center align-items-center'>

                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={prevPage} />

                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {
                        pageNumbers.map(pageNumber => (
                            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''} `}>
                                <button onClick={() => setCurrentPage(pageNumber)} className='page-link'>
                                    {pageNumber}
                                </button>
                            </li>
                        ))
                    }
                </ul>

                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={nextPage} />

            </nav>
        )
    }

    useEffect(() => {
        getModelosMaquina()
    }, [])

    const filteredModelosMaquina = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentModelosMaquina
        } else {
            return modelosMaquina.filter((modeloMaquina) => modeloMaquina.nome.toLowerCase().includes(lowerSearch) || modeloMaquina.id.toString().toLowerCase().includes(lowerSearch) || modeloMaquina.descricao?.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, modelosMaquina, currentModelosMaquina])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
            <div id='divContainer' className='container'>
                <DeleteModeloMaquinaConfirmaitonModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modeloMaquinaid={modeloMaquinaIdToDelete}
                    modeloMaquinaname={modeloMaquinaNameToDelete}
                />

                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        {/* <div id='divHeaderTittle'><AnimationModeloMaquinaLottie /><h3 id='tittleH2'>ModelosMaquina</h3></div> */}
                        <div id='divHeaderTittle'><AnimationModeloMaquinasLottie/><h3 id='tittleH2'>Modelos Maquina</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {
                            userPermissions.some((permission) => permission.name === 'store_modelo_maquina') ? (
                                <NavLink className='btnVoltarMobile' to='/modeloMaquina/new' style={{ textDecoration: 'none' }}>
                                    <Button id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='downloadDashButtonMobile'>Novo Modelo</span></Button>
                                </NavLink>
                            ) : ''
                        }
                    </div>
                </div>

                <ToastContainer />

                <div className='container overflow-auto mt-2'>
                    <Table id='tableList' striped bordered hover>
                        <thead>
                            <tr id="trList">
                                <th className='th-text-center'>#ID</th>
                                <th className='th-text-center'>Nome</th>
                                <th className='th-text-center'>Descrição</th>
                                <th id='thAction'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredModelosMaquina.map((modeloMaquina, index) => (
                                <tr key={modeloMaquina.id}>
                                    <td className='td-text-center fontCustom'>{modeloMaquina.id}</td>
                                    <td className='td-text-center fontCustom'>{modeloMaquina.nome}</td>
                                    <td className='td-text-center fontCustom'>{modeloMaquina.descricao}</td>
                                    <td className='text-center'>
                                        {
                                            userPermissions.some((permission) => permission.name === 'edit_modelo_maquina') ? (
                                                <Button className='editButton btnTableMoBile' onClick={() => showEditModeloMaquinaPage(modeloMaquina.id)}><BsPencilSquare /> Editar</Button>
                                            ) : ''
                                        }

                                        {
                                            userPermissions.some((permission) => permission.name === 'delete_modelo_maquina') ? (
                                                <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(modeloMaquina.id, modeloMaquina.name)}><FaTrash /> Excluir</Button>
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />

            </div>
            )}
        </>
    )
}

export default Index