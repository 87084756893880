import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'
import moment from 'moment'
import { Badge } from 'react-bootstrap'

import AnimationComandosMaquinaLottie from './AnimationComandosMaquinaLottie/AnimationComandosMaquinaLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Index = () => {
    const history = useNavigate()
    const [comandosMaquina, setComandosMaquina] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [comandoIdToDelete, setComandosMaquinaIdToDelete] = useState('')
    const [comandoNameToDelete, setComandosMaquinaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [comandoPerPage] = useState(10)
    const indexOfLastComando = currentPage * comandoPerPage
    const indexOfFirstComando = indexOfLastComando - comandoPerPage
    const currentComando = comandosMaquina.slice(indexOfFirstComando, indexOfLastComando)
    const nPages = Math.ceil(comandosMaquina.length / comandoPerPage)
    const [loading, setLoading] = useState(true);

    const getComandosMaquina = async () => {

        setLoading(true);

        try {
            const [userPermissionsResponse, comandosResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get(`/api/comandosMaquina/index`)
            ]);

            setUserPermissions(userPermissionsResponse.data.permissions);
            setComandosMaquina(comandosResponse.data.comandos);
        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get(`/api/comandosMaquina/index`)
        setComandosMaquina(response.data.comandos)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (comandoId, comandoName) => {
        setModalShow(true)
        setComandosMaquinaIdToDelete(comandoId)
        setComandosMaquinaNameToDelete(comandoName)
    }

    const deleteComando = async (comandoId) => {
        await api.delete(`/api/comandosMaquina/${comandoId}`).then(() => {
            getComandosMaquina()
            setModalShow(false)
            toast.success('Comando deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteComandoConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Comando Maquina.</h4>
                    <p>
                        Você tem certeza de que deseja remover o comando {props.comandoname} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteComando(props.comandoid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditComandoPage = (comandoId) => {
        history(`/comandoMaquina/edit/${comandoId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };


    useEffect(() => {
        getComandosMaquina()
    }, [])

    const filteredComandosMaquina = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentComando
        } else {
            return comandosMaquina.filter((comando) => comando.descricao.toLowerCase().includes(lowerSearch) || comando.nome_maquina.toLowerCase().includes(lowerSearch) || comando.id.toString().toLowerCase().includes(lowerSearch) || comando.comando.toLowerCase().includes(lowerSearch))
        }
    }, [search, comandosMaquina, currentComando])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className='container'>
                    <DeleteComandoConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        comandoid={comandoIdToDelete}
                        comandoname={comandoNameToDelete}
                    />

                    <div className='headerContainer'>
                        <div>
                            <div id='divHeaderTittle'><AnimationComandosMaquinaLottie /><h3 id='tittleH2'>Comandos Máquina</h3></div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder="pesquisar"
                                    aria-label="pesquisar"
                                    aria-describedby="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {
                                userPermissions.some((permission) => permission.name === 'store_commands') ? (
                                    <NavLink to='/comandoMaquina/new' style={{ textDecoration: 'none' }} className='btnVoltarMobile'>
                                        <Button id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Novo Comando</span></Button>
                                    </NavLink>
                                ) : ''
                            }
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Comando</th>
                                    <th className='text-center'>Máquina</th>
                                    <th className='text-center'>Data início</th>
                                    <th className='text-center'>Data fim</th>
                                    <th className='text-center'>Hora início</th>
                                    <th className='text-center'>Hora fim</th>
                                    <th className='text-center'>Persistente</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredComandosMaquina.map((comando, index) => (
                                    <tr key={comando.id} className={comando.ativo ? '' : 'table-danger'}>
                                        <td className='text-center'>{comando.id}</td>
                                        <td className='text-center'>{comando.descricao}</td>
                                        <td className='text-center'><div className='scrolling-text'>{comando.comando}</div></td>
                                        <td className='text-center'>{comando.nome_maquina}</td>
                                        <td className='text-center'><Badge className='bg-opacity-50 p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{moment(comando.data_inicio).format('DD/MM/YYYY')}</Badge></td>
                                        <td className='text-center'><Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{moment(comando.data_fim).format('DD/MM/YYYY')}</Badge></td>
                                        <td className='text-center'><Badge className='bg-opacity-50 p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{comando.horario_inicio}h</Badge></td>
                                        <td className='text-center'><Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{comando.horario_fim}h</Badge></td>
                                        <td className='text-center'>{comando.persistente ? 'Sim' : 'Não'}</td>
                                        <td className='text-center'>
                                            <div className='d-flex'>
                                                {
                                                    userPermissions.some((permission) => permission.name === 'edit_comando') ? (
                                                        <Button className='editButton btnTableMoBile' onClick={() => showEditComandoPage(comando.id)}><BsPencilSquare /> Editar</Button>
                                                    ) : ''
                                                }

                                                {
                                                    userPermissions.some((permission) => permission.name === 'delete_comando') ? (
                                                        <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(comando.id, comando.comando)}><FaTrash /> Excluir</Button>
                                                    ) : ''
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Index