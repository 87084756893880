import React, { useEffect, useState } from 'react'

const Preview = ({ material, doSpaces }) => {
  const [tamanhoFormatado, setTamanhoFormatado] = useState('')
  const [dolar, setDolar] = useState('4.50'); // Valores ficticios
  const [euro, setEuro] = useState('5.00'); // Valores ficticios
  const [min, setMin] = useState('20'); // Valores ficticios
  const [max, setMax] = useState('30'); // Valores ficticios

  useEffect(() => {
    if (material.id != null && material.value != null) {
      const calcularTamanhoFormatado = () => {
        if (parseInt(material.tamanho_bytes, 10) !== 0) {
          const base = Math.log(material.tamanho_bytes) / Math.log(1024);
          const suffixes = ['', 'KB', 'MB', 'GB', 'TB'];
          setTamanhoFormatado(`${(Math.pow(1024, base - Math.floor(base))).toFixed(2)} ${suffixes[Math.floor(base)]}`);
        } else {
          setTamanhoFormatado('0 Bytes');
        }
      };

      calcularTamanhoFormatado();

      fetch('https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL')
        .then(response => response.json())
        .then(data => {
          setEuro(data.EURBRL.bid);
          setDolar(data.USDBRL.bid);
        })
        .catch(error => console.error('Erro ao buscar dados da API:', error));

      fetch('https://api.openweathermap.org/data/2.5/forecast/daily?lang=pt&units=metric&q=S%C3%A3o%20Paulo&appid=110694ce97ac8f42ef2b095cd80a6681&cnt=7')
        .then(response => response.json())
        .then(data => {
          setMax(Math.round(data.list[0].temp.max));
          setMin(Math.round(data.list[0].temp.min));
        })
        .catch(error => console.error('Erro ao buscar dados da API:', error));
    }
  }, [material]);

  useEffect(() => {
    if (isNaN(dolar) || dolar === '') setDolar('4.50'); // Condição para substituir o erro renderizado
    if (isNaN(euro) || euro === '') setEuro('5.00'); // Condição para substituir o erro renderizado
    if (isNaN(min) || min === '') setMin('20'); // Condição para substituir o erro renderizado
    if (isNaN(max) || max === '') setMax('30'); // Condição para substituir o erro renderizado
  }, [dolar, euro, min, max]);

  const renderizarConteudo = () => {
    switch (material.tipo) {
      case 'zip':
        return (
          <iframe title="Zip Preview" src={`${doSpaces}unzip/${material.arquivo}/index.html?${material.cache_parametros}&maximahoje=${max}&minimahoje=${min}&valoreuro=${euro}&valordolar=${dolar}`} width="100%" height="600px" />
        );
      case 'imagem':
        return (
          <img style={{ width: '100%', maxHeight: '85vh' }} src={doSpaces + material.caminho + "/" + material.arquivo} alt="materialImage" />
        );
      case 'video':
        return (
          <video
            style={{ maxWidth: '90vh', backgroundColor: 'transparent' }}
            controls
          >
            <source
              src={doSpaces + material.caminho + "/" + material.arquivo}
              type="video/mp4"
            />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        );
      default:
        return null;
    }
  };

  const nomeArquivo = material.nome;
  const resolucao = material.resolucao;
  const conteudoPreview = renderizarConteudo();
  const tamanho = tamanhoFormatado;
  const duracao = material.duracao;

  return { nomeArquivo, resolucao, tamanho, duracao, conteudoPreview };
}

export default Preview;
