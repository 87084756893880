import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import AnimationRolesLottie from './AnimationRolesLottie/AnimationRolesLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [roles, setRoles] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [roleIdToDelete, setRoleIdToDelete] = useState('')
    const [roleNameToDelete, setRoleNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [rolesPerPage] = useState(10)
    const indexOfLastRole = currentPage * rolesPerPage
    const indexOfFirstRole = indexOfLastRole - rolesPerPage
    const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole)
    const nPages = Math.ceil(roles.length / rolesPerPage)
    const [loading, setLoading] = useState(true)

    const getRoles = async () => {

        setLoading(true)

        try {
            const [userPermissionsResponse, rolesResponse] = await Promise.all([
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/role/index')
            ])

            setUserPermissions(userPermissionsResponse.data.permissions)
            setRoles(rolesResponse.data.roles);

        } catch (error) {
            console.error('Erro')
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false)
        }

        const response = await api.get('/api/role/index')
        setRoles(response.data.roles)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (roleId, roleName) => {
        setModalShow(true)
        setRoleIdToDelete(roleId)
        setRoleNameToDelete(roleName)
    }

    const deleteRole = async (roleId) => {
        await api.delete(`/api/role/${roleId}`).then(() => {
            getRoles()
            setModalShow(false)
            toast.success('Perfil deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteRoleConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Perfil.</h4>
                    <p>
                        Você tem certeza de que deseja remover este perfil <span className='d-flex justify-content-center mb-0'>{props.rolename} ?</span>
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteRole(props.roleid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditRolePage = (roleId) => {
        history(`/role/edit/${roleId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;
    
        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };
    
        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }
    
        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };

    useEffect(() => {
        getRoles()
    }, [])

    const filteredRoles = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentRoles
        } else {
            return roles.filter((role) => role.name.toLowerCase().includes(lowerSearch) || role.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, roles, currentRoles])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <div id='divContainer' className='container'>
                    <DeleteRoleConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        roleid={roleIdToDelete}
                        rolename={roleNameToDelete}
                    />

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'><AnimationRolesLottie /><h3 id='tittleH2'>Perfis</h3></div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id='search'><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder='pesquisar'
                                    aria-label='pesquisar'
                                    aria-describedby='search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {
                                userPermissions.some((permission) => permission.name === 'store_role') ? (
                                    <NavLink className='btnVoltarMobile' to='/role/new' style={{ textDecoration: 'none' }}>
                                        <Button id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Novo Perfil</span></Button>
                                    </NavLink>
                                ) : ''
                            }
                        </div>
                    </div>

                    <ToastContainer />

                    <div>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr id='trList'>
                                    <th className='th-text-center'>#ID</th>
                                    <th className='th-text-center'>Nome</th>
                                    <th id='thAction'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRoles.map((role, index) => (
                                    <tr key={role.id}>
                                        <td className='td-text-center fontCustom'>{role.id}</td>
                                        <td className='td-text-center fontCustom'>{role.name}</td>
                                        <td id='tdButtonAction'>
                                            {
                                                userPermissions.some((permission) => permission.name === 'edit_role') ? (
                                                    <Button className='editButton btnTableMoBile me-2' onClick={ () => showEditRolePage(role.id) }><BsPencilSquare /> Editar</Button>
                                                ) : ''
                                            }

                                            {
                                                userPermissions.some((permission) => permission.name === 'delete_role') ? (
                                                    <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(role.id, role.name)}><FaTrash /> Excluir</Button>
                                                ) : ''
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage} 
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Index